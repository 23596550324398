import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { WorkLogFilter, WorkLogState } from "./worklog.types";
import { worklogApi } from "./worklog.api";
import { getCachedDateRange } from "../../components/SmartDateRangeSelector";
import { employeesApi } from "../employees/employees.api";

export const WORKLOG_LIST_DATE_FILETER_ID = "worklog_date_filter_list_filter";
const workLogListDateRange = getCachedDateRange(WORKLOG_LIST_DATE_FILETER_ID);

export const WORKLOG_SUMMARY_DATE_FILETER_ID = "worklog_date_filter_summary_filter";
const workLoSummaryDateRange = getCachedDateRange(WORKLOG_SUMMARY_DATE_FILETER_ID);

const initialState: WorkLogState = {
    searchResult: {
        items: [],
        total: 0
    },
    filter: {
        from: workLogListDateRange?.from,
        to: workLogListDateRange?.to,
        page: 1,
        pageSize: 50
    },
    summaryfilter: {
        from: workLoSummaryDateRange?.from,
        to: workLoSummaryDateRange?.to,
        page: 1,
        pageSize: 50
    },
    summary: [],
    employees: {}
}

export const searchWorkLogsAsync = createAsyncThunk(
    'worklog/search',
    async (filter: WorkLogFilter) => {
        return worklogApi.search(filter);
    }
);

export const getWorkLogsSummaryAsync = createAsyncThunk(
    'worklog/summary',
    async (filter: WorkLogFilter) => {
        return worklogApi.getSearchSummary(filter);
    }
);

export const fetchWorkShiftsAsync = createAsyncThunk(
    'worklog/workshifts',
    async () => {
        return worklogApi.getWorkShifts();
    }
);

export const fetchWorklogEmployeeAsync = createAsyncThunk(
    'worklog/employees',
    async (id: string) => {
        return employeesApi.get(id)
    }
);

export const worklogSlice = createSlice({
    name: 'worklog',
    initialState,
    reducers: {
        changeWorkLogFilter: (state, action: PayloadAction<any>) => {
            state.filter = { ...state.filter, ...action.payload }
        },
        changeWorkLogSummaryFilter: (state, action: PayloadAction<any>) => {
            state.summaryfilter = { ...state.summaryfilter, ...action.payload }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(searchWorkLogsAsync.fulfilled, (state, action) => {
            state.searchResult = action.payload
        }).addCase(getWorkLogsSummaryAsync.fulfilled, (state, action) => {
            state.summary = action.payload
        }).addCase(fetchWorkShiftsAsync.fulfilled, (state, action) => {
            state.workShifts = action.payload
        }).addCase(fetchWorklogEmployeeAsync.fulfilled, (state, action) => {
            state.employees = { ...state.employees, [action.meta.arg]: action.payload }
        })
    }
})

export default worklogSlice.reducer;

export const { changeWorkLogFilter, changeWorkLogSummaryFilter } = worklogSlice.actions;

export const worklogSelector = (state: RootState) => state.worklog.searchResult.items;
export const worklogSummarySelector = (state: RootState) => state.worklog.summary;
export const worklogTotalSelector = (state: RootState) => state.worklog.searchResult.total;
export const worklogFilterSelector = (state: RootState) => state.worklog.filter;
export const worklogSummaryFilterSelector = (state: RootState) => state.worklog.summaryfilter;
export const workShiftsSelector = (state: RootState) => state.worklog.workShifts;
export const employeesSelector = (state: RootState) => state.worklog.employees;