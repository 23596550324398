import { Suspense, useMemo } from "react"
import { Navigate, RouteObject, useRoutes } from "react-router-dom"
import PrivateRoute from "../components/PrivateRoute"
import { MAIN_NAV, NavElement } from "./nav"
import SapLoading from "../components/SapLoading"

export default function Routings() {
    const getRouteNodes = (nav: NavElement) => {
        let route: RouteObject = { path: nav.path };

        if (nav.component)
            route = {
                ...route,
                element: nav.allowAnonymous ? nav.component
                    : <PrivateRoute permission={nav.permission}>{nav.component}</PrivateRoute>
            };
        else if (nav.subElements?.length)
            route = { ...route, children: nav.subElements.map(sn => getRouteNodes(sn)) };

        return route;
    }

    const getLandingPage = () => {
        //TODO: Get the landing page based on the features tenant has subscribed. Also can consier the mobile view as well.
        return 'finance/data-entry';
    }

    const routes: RouteObject[] = useMemo(() => {
        const navRoutes: RouteObject[] = MAIN_NAV.map((n) => ({
            path: '/' + n.path,
            children: n.elements.map((sn) => getRouteNodes(sn))
        }));
        navRoutes.push({ path: '/', element: <Navigate to={getLandingPage()} /> });
        return navRoutes;
    }, [])

    return <Suspense fallback={<SapLoading />}>
        {useRoutes(routes)}
    </Suspense>
}