import { store } from "./store";
import { v4 as uuidv4 } from "uuid";
import { ErrorResponse, SapNotification } from "./types";
import { dismissToast, showToast } from "./core-slice";

export enum NotificationType {
    "error",
    "success",
    "warning",
}

export function getApiError(err: any): ErrorResponse {
    let code = "";
    let message = "";
    const status = err.response.status;

    switch (status) {
        case 403:
            code = "FORBIDDEN_ACCESS";
            message = "Anauthorized access. You shouldn't be here!";
            break;
        case 400:
            code = err.response?.data?.code || "ERR_BAD_REQUEST";
            message =
                err.response?.data?.message ||
                "Something wrong with the data you entered. Please contact SME Plus IT support.";
            break;
        default:
            code = "ERR_UNKNOWN";
            message = "Some thing went wrong. Please contact SME Plus IT support.";
            break;
    }
    return { code, message };
}

export function showApiErrorNotification(err: any) {
    const error = getApiError(err);
    showNotification(NotificationType.error, error.message, "Error");
}

export function showNotification(type: NotificationType, message: string, title?: string) {
    const id = uuidv4();
    const notification: SapNotification = {
        id,
        type,
        message,
        title,
    };

    store.dispatch(showToast(notification));

    setTimeout(
        () => {
            store.dispatch(dismissToast(id));
        },
        type === NotificationType.error ? 5000 : 3000
    );
}
