import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getAllProjects, getProject } from "./project-api";
import { ProjectFilter, ProjectState } from "./types";

const initialState: ProjectState = {
    projects: [],
    projectFilter: { showInactiveProjects: false, searchTerm: "" },
    searchInProgress: false,
};

export const fetchProjectsAsync = createAsyncThunk("project/projects", async (filter: ProjectFilter) => {
    const projects = await getAllProjects(filter);
    return projects;
});

export const fetchProjectToEditAsync = createAsyncThunk("project/editProject", async (id: string) => {
    const project = await getProject(id);
    return project;
});

export const projectSlice = createSlice({
    name: "project",
    initialState,
    reducers: {
        changeProjectFilter: (state, action: PayloadAction<any>) => {
            state.projectFilter = { ...state.projectFilter, ...action.payload };
        },
        clearEditingProject: (state) => {
            state.editingProject = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjectsAsync.fulfilled, (state, action) => {
                state.projects = action.payload;
                state.searchInProgress = false;
            })
            .addCase(fetchProjectsAsync.pending, (state, action) => {
                state.searchInProgress = true;
            })
            .addCase(fetchProjectsAsync.rejected, (state, action) => {
                state.searchInProgress = true;
            })
            .addCase(fetchProjectToEditAsync.fulfilled, (state, action) => {
                state.editingProject = action.payload;
            });
    },
});

export const { changeProjectFilter, clearEditingProject } = projectSlice.actions;

export const editingProjectSelector = (state: RootState) => state.project.editingProject;
export const projectFilterSelector = (state: RootState) => state.project.projectFilter;
export const projectsSelector = (state: RootState) => state.project.projects;
export const projectsSearchStatusSelector = (state: RootState) => state.project.searchInProgress;

export default projectSlice.reducer;
