import { coreApi } from "../../app/core-api";
import { Holiday } from "./config.types";

const controller = "configs";
const holidaycController = "holidays";

export const configApi = {
    getConfigValue,
    getHolidays,
};

export function getConfigValue(key: string) {
    return coreApi.get<string>(`${controller}/value/${key}`);
}

function getHolidays(year: number) {
    return coreApi.get<Holiday[]>(`${holidaycController}/${year}`);
}
