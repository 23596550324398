import { coreApi } from "../../app/core-api";
import { EntityCreateResult, PagedResult, PagedSearchFilter } from "../../app/types";
import {
    AttendanceDevice,
    AttendanceDeviceRegisterRequest,
    AttendanceLog,
    AttendanceLogFilter,
    AttendanceLogsFailedRetryResult,
} from "./attendance.types";

export const attendanceApi = {
    searchLogs,
    processFailedLogs,
    searchAllDevices,
    searchDevices,
    getDevice,
    registerDevice,
    updateDevice,
    reprovisionDevice,
    deleteDevice,
    checkDeviceProvisionStatus,
};

const controller = "attendance";

function searchLogs(filter: PagedSearchFilter<AttendanceLogFilter>) {
    return coreApi.get<PagedResult<AttendanceLog>>(`${controller}/logs`, { ...filter.filter, ...filter.pagination });
}

function processFailedLogs() {
    return coreApi.post<AttendanceLogsFailedRetryResult>(`${controller}/logs/process-failed`, {});
}

function searchAllDevices() {
    return coreApi.get<AttendanceDevice[]>(`${controller}/devices/all`);
}

function searchDevices() {
    return coreApi.get<AttendanceDevice[]>(`${controller}/devices`);
}

function getDevice(id: string) {
    return coreApi.get<AttendanceDevice>(`${controller}/devices/${id}`);
}

function registerDevice(device: AttendanceDeviceRegisterRequest) {
    return coreApi.post<EntityCreateResult>(`${controller}/devices`, device);
}

function updateDevice(id: string, device: AttendanceDeviceRegisterRequest) {
    return coreApi.put(`${controller}/devices/${id}`, device);
}

function reprovisionDevice(id: string) {
    return coreApi.post(`${controller}/devices/${id}/reprovision`, {});
}

function deleteDevice(id: string) {
    return coreApi.remove(`${controller}/devices/${id}`);
}

function checkDeviceProvisionStatus(id: string) {
    return coreApi.get(`${controller}/devices/${id}/provision-status`);
}
