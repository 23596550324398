import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

export function UnAuthLayout() {
    return (
        <div className="unauth-layout">
            <header className="unauth-layout-header bg-dark">
                <img src="/logo.png" alt="SME Plus Logo" className="unauth-layout-logo" />
            </header>
            <main className="unauth-layout-content">
                <Outlet />
            </main>
            <footer className="unauth-layout-footer">
                <Footer thin />
            </footer>
        </div>
    );
}
