import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { ConfigState } from "./config.types";
import { configApi, getConfigValue } from "./config.api";

const initialState: ConfigState = {
    configValues: {},
    holidays: {},
};

export const getconfigValueAsync = createAsyncThunk("config/value", async (key: string) => {
    const value = await getConfigValue(key);
    return { [key]: value };
});

export const fetchHolidaysAsync = createAsyncThunk("config/holidays", async (year: number) => {
    const holidays = await configApi.getHolidays(year);
    return { [year]: holidays };
});

const configSlice = createSlice({
    name: "config",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getconfigValueAsync.fulfilled, (state, action) => {
                state.configValues = { ...state.configValues, ...action.payload };
            })
            .addCase(fetchHolidaysAsync.fulfilled, (state, action) => {
                state.holidays = { ...state.holidays, ...action.payload };
            });
    },
});

export const {} = configSlice.actions;

export default configSlice.reducer;

export const configValueSelector = (state: RootState) => {
    return (key: string) => state.config.configValues[key];
};

export const holidaysSelector = (state: RootState) => state.config.holidays;
