import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AttendanceLogFilter, AttendanceState } from "./attendance.types";
import { attendanceApi } from "./attendance.api";
import { RootState } from "../../app/store";
import { PagedSearchFilter } from "../../app/types";

const initialState: AttendanceState = {
    searchResult: {
        items: [],
        total: 0,
    },
    filter: {},
    attendanceLogSearchInProgress: false,
};

export const searchAttendanceLogsAsync = createAsyncThunk(
    "attendance/logs/search",
    async (filter: PagedSearchFilter<AttendanceLogFilter>) => {
        return attendanceApi.searchLogs(filter);
    }
);

export const searchAttendanceDevicesAsync = createAsyncThunk("attendance/devices", async (all: boolean = false) => {
    return all ? attendanceApi.searchAllDevices() : attendanceApi.searchDevices();
});

const attendanceSlice = createSlice({
    name: "attendance",
    initialState,
    reducers: {
        changeAttendanceLogFilter: (state, action: PayloadAction<any>) => {
            state.filter = { ...state.filter, ...action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchAttendanceLogsAsync.pending, (state) => {
                state.attendanceLogSearchInProgress = true;
            })
            .addCase(searchAttendanceLogsAsync.fulfilled, (state, action) => {
                state.searchResult = action.payload;
                state.attendanceLogSearchInProgress = false;
            })
            .addCase(searchAttendanceLogsAsync.rejected, (state) => {
                state.attendanceLogSearchInProgress = false;
            })
            .addCase(searchAttendanceDevicesAsync.fulfilled, (state, action) => {
                state.devices = action.payload;
            });
    },
});

export const { changeAttendanceLogFilter } = attendanceSlice.actions;

export default attendanceSlice.reducer;

export const attendanceLogsSelector = (state: RootState) => state.attendance.searchResult;
export const attendanceLogFilterSelector = (state: RootState) => state.attendance.filter;
export const attendanceLogSearchInProgressSelector = (state: RootState) =>
    state.attendance.attendanceLogSearchInProgress;
export const attendanceDevicesSelector = (state: RootState) => state.attendance.devices;
