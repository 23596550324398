import { IDictionary, ListItem } from "../../app/types";

export interface DashboardData {
    from?: string;
    to?: string;
    items: DashboardDataItem[];
    projects: ListItem[];
}

export interface DashboardDataItem {
    projectId: string;
    category: number;
    typeId?: string;
    type?: string;
    amount: number;
}

export interface DashboardFilter {
    from?: string;
    to?: string;
    projectIds?: string[];
    view: DashboardView;
}

export interface DashboardState {
    filter: DashboardFilter;
    data: DashboardData;
    businessData: DashboardDataItem[];
    projectData: IDictionary<DashboardData>;
    loading?: boolean;
}

export enum DashboardView {
    Business,
    Projects
}