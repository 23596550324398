export interface ListItem {
    key: string;
    value: string;
    inactive?: boolean;
}

export interface NestedListItem extends ListItem {
    subItems: ListItem[];
}

//TODO: Remove this after refactoring
export interface PagedFilter {
    page: number;
    pageSize: number;
}

export interface Pagination {
    page: number;
    pageSize: number;
}

export interface PagedSearchFilter<T> {
    filter: T;
    pagination: Pagination;
}

export interface PagedResult<T> {
    total: number;
    items: T[];
    pageSize?: number;
    page?: number;
}

export interface PaginatorData {
    currentPage: number;
    totalPages: number;
    pageSize: number;
}

export interface Dictionary {
    [key: string]: string;
}

export interface IDictionary<T> {
    [key: string]: T;
}

export interface SapNotification {
    type: NotificationType;
    message: string;
    title?: string;
    id: string;
}

export interface EntityCreateResult {
    id: string;
    concurrencyKey: string;
}

export interface EntityUpdateResult {
    concurrencyKey: string;
}

export interface ConcurrencyHandledDto {
    concurrencyKey?: string;
}

export interface AuditedEntity {
    createdBy: string;
    createdDateUtc: string;
    lastUpdatedBy: string;
    lastUpdatedDateUtc: string;
}

export interface AuditedEntity2 {
    createdBy?: string;
    createdDateUtc?: string;
    lastUpdatedBy?: string;
    lastUpdatedDateUtc?: string;
}

export interface AuditedAndConcurrencyHandledDto extends ConcurrencyHandledDto, AuditedEntity {}

export interface ErrorResponse {
    code: string;
    message: string;
}

export enum NotificationType {
    "error",
    "success",
    "warning",
}
