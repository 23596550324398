import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AuthState, AuthUser } from "./auth.types";
import { tenantApi } from "../tenant/tenant.api";

const initialState: AuthState = {
    showTenantSwitcher: false,
    initialized: false,
};

export const fetchFeaturesAsync = createAsyncThunk("tenant/subscription/features", async () => {
    const features = await tenantApi.getCurrentTenantFeatures();
    return features;
});

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthInitialized: (state, action: PayloadAction<boolean>) => {
            state.initialized = action.payload;
        },
        setShowTenantSwitcher: (state, action: PayloadAction<boolean>) => {
            state.showTenantSwitcher = action.payload;
        },
        setAuthUser: (state, action: PayloadAction<AuthUser | undefined>) => {
            state.user = action.payload;
        },
        setFeatures: (state, action: PayloadAction<string[]>) => {
            state.features = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFeaturesAsync.fulfilled, (state, action) => {
            state.features = action.payload;
        });
    },
});

export const { setAuthInitialized, setShowTenantSwitcher, setAuthUser, setFeatures } = authSlice.actions;

export const selectAuthUser = (state: RootState) => state.auth.user;
export const authSelector = (state: RootState) => !!state.auth.user;
export const hasPermission = (state: RootState, permission: string) =>
    !!state.auth.user && state.auth.user.permissions.includes(permission);
export const selectFeatures = (state: RootState) => state.auth.features;

export default authSlice.reducer;
