import { useNavigate } from "react-router-dom";
import Copyright from "./Copyright";

interface Props {
    thin?: boolean;
}

export default function Footer({ thin }: Props) {
    const navigate = useNavigate();

    const goToPrivacyPolicy = () => {
        navigate("/privacy");
    };

    return (
        <footer className={`py-${thin ? 2 : 4} bg-light mt-auto`}>
            <div className="container-fluid px-2">
                <div className="d-flex align-items-center justify-content-between small">
                    <div className="text-muted small">
                        <Copyright />
                    </div>
                    <div className="text-end">
                        <a href="#" className="text-muted small" onClick={goToPrivacyPolicy}>
                            Privacy Policy
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
