import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
    CropAnalyticsFilter,
    CropLogModalProps,
    CropStatBulkUpdatePartialDto,
    CropStatFilter,
    CropTrackingState,
    TreeFilter,
} from "./crop-tracking.types";
import { cropTrackingApi } from "./crop-tracking.api";
import { PagedSearchFilter } from "../../app/types";

const initialState: CropTrackingState = {
    treesSearchResult: {
        items: [],
        total: 0,
    },
    treesFilter: {},
    cropingSeasonsFilter: {
        page: 1,
        pageSize: 10,
    },
    cropingSeasonsSearchResult: {
        items: [],
        total: 0,
    },
    cropStatsFilter: {},
    cropStatsSearchResult: {
        items: [],
        total: 0,
    },
    cropingSeasonsListItems: {},
    cropStatBulkUpdateData: {},
    cropLogModal: {
        show: false,
    },
    cropAnalyticsFilter: {},
    cropStatsByZone: [],
    fallenStatsByZone: [],
};

export const searchTreesAsync = createAsyncThunk(
    "crop-tracking/trees/search",
    async (filter: PagedSearchFilter<TreeFilter>) => {
        return cropTrackingApi.searchTrees(filter);
    }
);

export const searchCropingSeasonsAsync = createAsyncThunk(
    "crop-tracking/croping-seasons/search",
    async (filter: any) => {
        return cropTrackingApi.searchCropingSeasons(filter);
    }
);

export const searchCropStatsAsync = createAsyncThunk(
    "crop-tracking/crop-stats/search",
    async (filter: PagedSearchFilter<CropStatFilter>) => {
        return cropTrackingApi.searchCropStats(filter);
    }
);

export const fetchCropingSeasonsListItemsAsync = createAsyncThunk(
    "crop-tracking/croping-seasons/list-items",
    async (projectId: string) => {
        return cropTrackingApi.getCropingSeasonsListItems(projectId);
    }
);

export const refreshSingleCropStatAsync = createAsyncThunk(
    "crop-tracking/crop-stats/refresh-single",
    async (id: string) => {
        return cropTrackingApi.getCropStat(id);
    }
);

export const fetchCropStatsByZoneAsync = createAsyncThunk(
    "crop-tracking/crop-analytics/stats-by-zone",
    async (filter: CropAnalyticsFilter) => {
        return cropTrackingApi.getCropStatsByZone(filter);
    }
);

export const fetchFallenStatsByZoneAsync = createAsyncThunk(
    "crop-tracking/crop-analytics/fallen-stats",
    async (filter: CropAnalyticsFilter) => {
        return cropTrackingApi.getFallenStatsByZone(filter);
    }
);
export const cropTrackingSlice = createSlice({
    name: "cropTracking",
    initialState,
    reducers: {
        changeTreeSearchFilter: (state, action: PayloadAction<any>) => {
            state.treesFilter = { ...state.treesFilter, ...action.payload };
        },
        changeCropingSeasonsSearchFilter: (state, action: PayloadAction<any>) => {
            state.cropingSeasonsFilter = { ...state.cropingSeasonsFilter, ...action.payload };
        },
        changeCropStatsSearchFilter: (state, action: PayloadAction<any>) => {
            state.cropStatsFilter = { ...state.cropStatsFilter, ...action.payload };
        },
        changeCropAnaliticsFilter: (state, action: PayloadAction<any>) => {
            state.cropAnalyticsFilter = { ...state.cropAnalyticsFilter, ...action.payload };
        },
        changeCropStatsBulkUpdateMode: (state, action: PayloadAction<boolean>) => {
            state.bulkUpdateModeOn = action.payload;
        },
        setCropStatBulkUpdateData: (state, action: PayloadAction<CropStatBulkUpdatePartialDto>) => {
            state.cropStatBulkUpdateData = {
                ...state.cropStatBulkUpdateData,
                [action.payload.cropStatId]: {
                    ...state.cropStatBulkUpdateData[action.payload.cropStatId],
                    ...action.payload.data,
                },
            };
        },
        clearCropStatBulkUpdateData: (state) => {
            state.cropStatBulkUpdateData = {};
        },
        setCropLogModalProps: (state, action: PayloadAction<CropLogModalProps>) => {
            state.cropLogModal = action.payload;
        },
        setCropStatListShowLogs: (state, action: PayloadAction<string | undefined>) => {
            const logs = [...state.cropStatsSearchResult.items];
            if (action.payload) {
                const index = logs.findIndex((x) => x.id === action.payload);
                if (index !== -1) {
                    logs[index].showLogs = true;
                }
            } else {
                logs.forEach((x) => {
                    x.showLogs = true;
                });
            }
            state.cropStatsSearchResult.items = logs;
        },
        setCropStatListHideLogs: (state, action: PayloadAction<string | undefined>) => {
            const logs = [...state.cropStatsSearchResult.items];
            if (action.payload) {
                const index = logs.findIndex((x) => x.id === action.payload);
                if (index !== -1) {
                    logs[index].showLogs = false;
                }
            } else {
                logs.forEach((x) => {
                    x.showLogs = false;
                });
            }
            state.cropStatsSearchResult.items = logs;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchTreesAsync.fulfilled, (state, action) => {
                state.treesSearchResult = action.payload;
                state.treesSearchInprogress = false;
            })
            .addCase(searchTreesAsync.pending, (state, action) => {
                state.treesSearchInprogress = true;
            })
            .addCase(searchTreesAsync.rejected, (state, action) => {
                state.treesSearchInprogress = false;
            })
            .addCase(searchCropingSeasonsAsync.fulfilled, (state, action) => {
                state.cropingSeasonsSearchResult = action.payload;
                state.cropingSeasonsSearchInprogress = false;
            })
            .addCase(searchCropingSeasonsAsync.pending, (state, action) => {
                state.cropingSeasonsSearchInprogress = true;
            })
            .addCase(searchCropingSeasonsAsync.rejected, (state, action) => {
                state.cropingSeasonsSearchInprogress = false;
            })
            .addCase(fetchCropingSeasonsListItemsAsync.fulfilled, (state, action) => {
                state.cropingSeasonsListItems[action.meta.arg] = action.payload;
            })
            .addCase(searchCropStatsAsync.fulfilled, (state, action) => {
                state.cropStatsSearchResult = action.payload;
                state.cropStatsSearchInprogress = false;
            })
            .addCase(searchCropStatsAsync.pending, (state, action) => {
                state.cropStatsSearchInprogress = true;
            })
            .addCase(searchCropStatsAsync.rejected, (state, action) => {
                state.cropStatsSearchInprogress = false;
            })
            .addCase(refreshSingleCropStatAsync.fulfilled, (state, action) => {
                const index = state.cropStatsSearchResult.items.findIndex((x) => x.id === action.payload.id);
                if (index !== -1) {
                    action.payload.showLogs = state.cropStatsSearchResult.items[index].showLogs;
                    state.cropStatsSearchResult.items[index] = action.payload;
                }
            })
            .addCase(fetchCropStatsByZoneAsync.pending, (state, action) => {
                state.cropAnalyticsSearchInprogress = true;
            })
            .addCase(fetchCropStatsByZoneAsync.fulfilled, (state, action) => {
                state.cropStatsByZone = action.payload;
                state.cropAnalyticsSearchInprogress = false;
            })
            .addCase(fetchCropStatsByZoneAsync.rejected, (state, action) => {
                state.cropAnalyticsSearchInprogress = false;
            })
            .addCase(fetchFallenStatsByZoneAsync.fulfilled, (state, action) => {
                state.fallenStatsByZone = action.payload;
            });
    },
});

export default cropTrackingSlice.reducer;

export const {
    changeTreeSearchFilter,
    changeCropingSeasonsSearchFilter,
    changeCropStatsSearchFilter,
    changeCropStatsBulkUpdateMode,
    setCropStatBulkUpdateData,
    clearCropStatBulkUpdateData,
    setCropLogModalProps,
    setCropStatListShowLogs,
    setCropStatListHideLogs,
    changeCropAnaliticsFilter,
} = cropTrackingSlice.actions;

export const treesSelector = (state: RootState) => state.cropTracking.treesSearchResult;
export const treesFilterSelector = (state: RootState) => state.cropTracking.treesFilter;
export const treesSearchInprogressSelector = (state: RootState) => state.cropTracking.treesSearchInprogress;

export const cropingSeasonsSelector = (state: RootState) => state.cropTracking.cropingSeasonsSearchResult.items;
export const cropingSeasonsTotalSelector = (state: RootState) => state.cropTracking.cropingSeasonsSearchResult.total;
export const cropingSeasonsFilterSelector = (state: RootState) => state.cropTracking.cropingSeasonsFilter;
export const cropingSeasonsSearchInprogressSelector = (state: RootState) =>
    state.cropTracking.cropingSeasonsSearchInprogress;

export const cropStatsSelector = (state: RootState) => state.cropTracking.cropStatsSearchResult;
export const cropStatsFilterSelector = (state: RootState) => state.cropTracking.cropStatsFilter;
export const cropStatsSearchInprogressSelector = (state: RootState) => state.cropTracking.cropStatsSearchInprogress;
export const cropingSeasonsListItemsSelector = (state: RootState) => state.cropTracking.cropingSeasonsListItems;
export const bulkUpdateModeSelector = (state: RootState) => state.cropTracking.bulkUpdateModeOn;
export const cropStatBulkUpdateDataSelector = (state: RootState) => state.cropTracking.cropStatBulkUpdateData;

export const cropLogModalPropsSelector = (state: RootState) => state.cropTracking.cropLogModal;

export const cropAnalyticsFilterSelector = (state: RootState) => state.cropTracking.cropAnalyticsFilter;
export const cropStatsByZoneSelector = (state: RootState) => state.cropTracking.cropStatsByZone;
export const fallenStatsByZoneSelector = (state: RootState) => state.cropTracking.fallenStatsByZone;
export const cropAnalyticsSearchInprogressSelector = (state: RootState) =>
    state.cropTracking.cropAnalyticsSearchInprogress;
