import axios, { AxiosRequestConfig } from "axios";
import { storageHelper, storageKeys } from "./storage-helper";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

// Setup Axios interceptors
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Redirect to login page
            window.location.replace("/account/login");
        }
        return Promise.reject(error);
    }
);

export const coreApi = {
    post,
    get,
    put,
    patch,
    remove,
    download,
};

function getConfig(): AxiosRequestConfig<any> {
    return {
        headers: {
            Authorization: `bearer ${storageHelper.getValue(storageKeys.authToken) || ""}`,
        },
    };
}

function post<T>(path: string, data: any) {
    return axios.post<T>(path, data, getConfig()).then((r) => r.data);
}

function patch<T>(path: string, data: any) {
    return axios.patch<T>(path, data, getConfig()).then((r) => r.data);
}

function put<T>(path: string, data: any) {
    return axios.put<T>(path, data, getConfig()).then((r) => r.data);
}

function get<T>(path: string, queryParams?: any) {
    return axios.get<T>(`${path}${queryParams ? createQueryString(queryParams) : ""}`, getConfig()).then((r) => r.data);
}

function remove<T>(path: string, queryParams?: any) {
    return axios
        .delete<T>(`${path}${queryParams ? createQueryString(queryParams) : ""}`, getConfig())
        .then((r) => r.data);
}

function download(path: string, queryParams?: any) {
    return axios
        .get(`${path}${queryParams ? createQueryString(queryParams) : ""}`, { ...getConfig(), responseType: "blob" })
        .then((r) => {
            const blob = new Blob([r.data]);
            const filename = getFileName(r.headers["content-disposition"]) || "file";
            saveFile(blob, filename);
        });
}

function saveFile(blob: Blob, filename: string) {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);
    if (typeof tempLink.download === "undefined") {
        tempLink.setAttribute("target", "_blank");
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
}

function createQueryString(obj: any) {
    const qs = Object.keys(obj)
        .map((key) => `${key}=${obj[key] === undefined ? "" : obj[key]}`)
        .join("&");

    return "?" + qs;
}

function getFileName(contentDisp: string) {
    const elems = contentDisp.split(" ");
    const filename = elems
        .find((e) => e.startsWith("filename="))
        ?.split("=")[1]
        .trimEnd()
        ?.slice(0, -1);
    return filename;
}
