import { coreApi } from "../../app/core-api";
import { EntityCreateResult, EntityUpdateResult, PagedResult } from "../../app/types";
import { HarvestLog, HarvestLogEntry, HarvestLogFilter, HarvestLogListItem, HarvestSummary } from "./types";

const controller = 'harvestlogs';

export function getHarvestLogs(filter: HarvestLogFilter) {
  return coreApi.get<PagedResult<HarvestLogListItem>>(controller, filter);
}

export function getHarvestSummary(filter: HarvestLogFilter) {
  return coreApi.get<HarvestSummary>(`${controller}/summary`, filter);
}

export function createHarvestLog(data: HarvestLogEntry) {
  return coreApi.post<EntityCreateResult>(controller, data);
}

export function updateHarvestLog(id: string, data: HarvestLogEntry) {
  return coreApi.put<EntityUpdateResult>(`${controller}/${id}`, data);
}

export function getHarvestLog(id: string) {
  return coreApi.get<HarvestLog>(`${controller}/${id}`);
}

export function deleteHarvestLog(id: string) {
  return coreApi.remove(`${controller}/${id}`);
}
