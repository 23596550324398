import dayjs from "dayjs";
import { ListItem } from "./types";

export const dateHelpers = {
    dateMonthsAgo,
    toIsoString,
    toDate,
    toDisplayString,
    toShortDateString,
    toDatetTimeString,
    toDateString,
    toIsoString2,
    formatDate,
    toTime,
    minutesToTimestamp,
};

export const currencyHelpers = {
    toCurrency,
};

export const objHelpers = {
    deepClone,
};

export const passwordHelpers = {
    generateRandomString,
};

export const enumHelpers = {
    createListItemsFromEnum,
};

function dateMonthsAgo(months: number) {
    var d = new Date();
    d.setMonth(d.getMonth() - months);
    return d;
}

function toIsoString(value?: Date, includeTime?: boolean) {
    return value ? dayjs(value).format(includeTime ? "YYYY-MM-DDTHH:mm:ss" : "YYYY-MM-DD") : "";
}
function formatDate(date: string, format: string) {
    return dayjs(date).format(format);
}

function toDate(valueStr: string) {
    return new Date(valueStr);
}

function toDisplayString(date: string) {
    return dayjs(date).format("DD/MM/YY h:mm A");
}

function toDatetTimeString(date: string) {
    return dayjs(date).format("DD/MM/YYYY h:mm A");
}

function toTime(date: string) {
    return dayjs(date).format("h:mm A");
}

function toDateString(date: string) {
    return dayjs(date).format("DD/MM/YYYY");
}

function toShortDateString(date: string) {
    return dayjs(date).format("DD/MM/YY");
}

function toIsoString2(date: string) {
    return dayjs(date).format("YYYY-MM-DD");
}

function minutesToTimestamp(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = remainingMinutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} Hr`;
}

function toCurrency(amount: number, hideDecimals: boolean = false) {
    if (hideDecimals) amount = Math.round(amount);
    return amount.toFixed(hideDecimals ? 0 : 2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function deepClone<T>(obj: T) {
    return JSON.parse(JSON.stringify(obj)) as T;
}

function generateRandomString(length: number): string {
    const uppercase = "ABCDEFGHJKLMNPQRSTUVWXYZ";
    const lowercase = "abcdefghjkmnpqrstuvwxyz";
    const numbers = "23456789";
    const allCharacters = uppercase + lowercase + numbers;

    if (length < 3) {
        throw new Error("Length should be at least 3 to include one character from each group.");
    }

    let result = "";
    result += uppercase.charAt(Math.floor(Math.random() * uppercase.length));
    result += lowercase.charAt(Math.floor(Math.random() * lowercase.length));
    result += numbers.charAt(Math.floor(Math.random() * numbers.length));

    for (let i = 3; i < length; i++) {
        result += allCharacters.charAt(Math.floor(Math.random() * allCharacters.length));
    }

    return shuffleString(result);
}

function shuffleString(str: string): string {
    const array = str.split("");
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array.join("");
}

export function createListItemsFromEnum(enumObj: any): ListItem[] {
    return Object.keys(enumObj)
        .filter((key) => isNaN(Number(key)))
        .map((key) => ({
            key: enumObj[key],
            value: key.replaceAll("_", " "),
        }));
}
