import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "../features/auth/auth.slice";
import financeReducer from "../features/finance/finance-slice";
import worklogReducer from "../features/worklog/worklog.slice";
import harvestlogReducer from "../features/harvest/harvestlog-slice";
import lookupReducer from "../features/lookup/lookup-slice";
import configReducer from "../features/config/config.slice";
import coreReducer from "./core-slice";
import projectReducer from "../features/project/project-slice";
import tenantReducer from "../features/tenant/tenant.slice";
import dashboardReducer from "../features/dashboard/dashboard-slice";
import employeesReducer from "../features/employees/employees.slice";
import payrollReducer from "../features/payroll/payroll.slice";
import cropTrackingReducer from "../features/crop-tracking/crop-tracking.slice";
import attendanceReducer from "../features/attendance/attendance.slice";
import usersReducer from "../features/users/users.slice";
import leaveReducer from "../features/leave/leave.slice";

export const store = configureStore({
    reducer: {
        core: coreReducer,
        auth: authReducer,
        finance: financeReducer,
        worklog: worklogReducer,
        lookup: lookupReducer,
        config: configReducer,
        project: projectReducer,
        tenant: tenantReducer,
        dashboard: dashboardReducer,
        harvest: harvestlogReducer,
        employees: employeesReducer,
        payroll: payrollReducer,
        cropTracking: cropTrackingReducer,
        attendance: attendanceReducer,
        users: usersReducer,
        leave: leaveReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
