import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { EmployeesState } from "./employees.types";
import { employeesApi } from "./employees.api";

const initialState: EmployeesState = {
    searchResult: {
        items: [],
        total: 0
    }
}

export const searchEmployeesAsync = createAsyncThunk(
    'employees/search',
    async () => {
        return employeesApi.search();
    }
);

export const getEmployeesListItemsAsync = createAsyncThunk(
    'employees/listItems',
    async () => {
        return employeesApi.getListItems();
    }
);

export const getEmployeeContractTypesAsync = createAsyncThunk(
    'employees/contractTypes',
    async () => {
        return employeesApi.getContractTypes();
    }
);

export const employeesSlice = createSlice({
    name: 'employees',
    initialState,
    reducers: {
        clearEmployeeListItems: (state) => {
            state.listItems = undefined;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(searchEmployeesAsync.fulfilled, (state, action) => {
            state.searchResult = action.payload;
            state.searchInprogress = false;
        }).addCase(searchEmployeesAsync.pending, (state, action) => {
            state.searchInprogress = true;
        }).addCase(searchEmployeesAsync.rejected, (state, action) => {
            state.searchInprogress = false;
        }).addCase(getEmployeesListItemsAsync.fulfilled, (state, action) => {
            state.listItems = action.payload
        }).addCase(getEmployeeContractTypesAsync.fulfilled, (state, action) => {
            state.employmentContractTypes = action.payload
        })
    }
})

export default employeesSlice.reducer;

export const { clearEmployeeListItems } = employeesSlice.actions;

export const employeesListSelector = (state: RootState) => state.employees.searchResult.items;
export const employeesListItemsSelector = (state: RootState) => state.employees.listItems;
export const employeesSearchProgressSelector = (state: RootState) => state.employees.searchInprogress;
export const employeesContractTypesSelector = (state: RootState) => state.employees.employmentContractTypes;
