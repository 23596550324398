import { coreApi } from "../../app/core-api";
import { EntityCreateResult, EntityUpdateResult, ListItem, PagedResult } from "../../app/types";
import { WorkLog, WorkLogFilter, WorkLogsSummary } from "./worklog.types";

export const worklogApi = {
    search,
    create,
    update,
    get,
    getWorkShifts,
    del,
    getSearchSummary,
    downloadWorkLogsReport,
};

const controller = "worklogs";

function search(filter: WorkLogFilter) {
    return coreApi.get<PagedResult<WorkLog>>(controller, filter);
}

function getWorkShifts() {
    return coreApi.get<ListItem[]>(`${controller}/WorkShifts`, {});
}

function create(data: WorkLog) {
    return coreApi.post<EntityCreateResult>(controller, data);
}

function update(id: string, data: WorkLog) {
    return coreApi.put<EntityUpdateResult>(`${controller}/${id}`, data);
}

function get(id: string) {
    return coreApi.get<WorkLog>(`${controller}/${id}`);
}

function del(id: string) {
    return coreApi.remove(`${controller}/${id}`);
}

function getSearchSummary(filter: WorkLogFilter) {
    return coreApi.get<WorkLogsSummary[]>(`${controller}/Summary`, filter);
}

function downloadWorkLogsReport(filter: WorkLogFilter) {
    return coreApi.download(`WorkLogs/Report`, filter);
}
