import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { TenantState } from "./tenant.types";
import { tenantApi } from "./tenant.api";

const initialState: TenantState = {
    tenants: [],
    subscribedTenants: [],
    allTenants: [],
};

export const fetchAllTenantsAsync = createAsyncThunk("tenant/allTenants", async () => {
    const tenants = await tenantApi.getAllTenants();
    return tenants;
});

export const fetchTenantsAsync = createAsyncThunk("tenant/tenants", async () => {
    const tenants = await tenantApi.getTenants();
    return tenants;
});

export const fetchSubscribedTenantsAsync = createAsyncThunk("tenant/tenants/subscribed", async () => {
    const tenants = await tenantApi.getSubscribedTenants();
    return tenants;
});

export const fetchAllFeaturesAsync = createAsyncThunk("tenant/features", async () => {
    const features = await tenantApi.getAllFeatures();
    return features;
});

export const fetchNatureOfBusinessesAsync = createAsyncThunk("tenant/natureOfBusinesses", async () => {
    const natureOfBusinesses = await tenantApi.getNatureOfBusinesses();
    return natureOfBusinesses;
});

export const tenantSlice = createSlice({
    name: "tenant",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTenantsAsync.fulfilled, (state, action) => {
                state.tenants = action.payload;
            })
            .addCase(fetchSubscribedTenantsAsync.fulfilled, (state, action) => {
                state.subscribedTenants = action.payload;
                state.loadingSubscribedTenants = false;
            })
            .addCase(fetchSubscribedTenantsAsync.pending, (state, action) => {
                state.loadingSubscribedTenants = true;
            })
            .addCase(fetchAllTenantsAsync.fulfilled, (state, action) => {
                state.allTenants = action.payload;
            })
            .addCase(fetchAllFeaturesAsync.fulfilled, (state, action) => {
                state.allFeatures = action.payload;
            })
            .addCase(fetchNatureOfBusinessesAsync.fulfilled, (state, action) => {
                state.natureOfBusinesses = action.payload;
            });
    },
});

export const {} = tenantSlice.actions;

export const tenantsSelector = (state: RootState) => state.tenant.tenants;
export const subscribedTenantsSelector = (state: RootState) => state.tenant.subscribedTenants;
export const allTenantsSelector = (state: RootState) => state.tenant.allTenants;
export const allFeaturesSelector = (state: RootState) => state.tenant.allFeatures;
export const natureOfBusinessesSelector = (state: RootState) => state.tenant.natureOfBusinesses;

export default tenantSlice.reducer;
