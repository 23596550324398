import { coreApi } from "../../app/core-api";
import { EntityCreateResult, EntityUpdateResult, ListItem } from "../../app/types";
import { Project, ProjectInputDto, ProjectFilter } from "./types";

export function getAllProjects(filter: ProjectFilter) {
    return coreApi.get<Project[]>("Projects", filter);
}

export function createProject(data: ProjectInputDto) {
    return coreApi.post<EntityCreateResult>("Projects", data);
}

export function updateProject(id: string, data: ProjectInputDto) {
    return coreApi.put<EntityUpdateResult>(`Projects/${id}`, data);
}

export function getProject(id: string) {
    return coreApi.get<Project>(`Projects/${id}`);
}

export function deleteProject(id: string) {
    return coreApi.remove(`Projects/${id}`);
}
