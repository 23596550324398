import { coreApi } from "../../app/core-api";
import { EntityCreateResult, EntityUpdateResult, ListItem, PagedResult } from "../../app/types";
import { Employee, EmployeeListItem, EmploymentContractType } from "./employees.types";

export const employeesApi = {
  search,
  create,
  update,
  get,
  getListItems,
  del,
  getContractTypes
}

const controller = "employees"

function search() {
  return coreApi.get<PagedResult<EmployeeListItem>>(controller, {});
}

function getListItems() {
  return coreApi.get<ListItem[]>(`${controller}/ListItems`, {});
}

function create(data: Employee) {
  return coreApi.post<EntityCreateResult>(controller, data);
}

function update(id: string, data: Employee) {
  return coreApi.put<EntityUpdateResult>(`${controller}/${id}`, data);
}

function get(id: string) {
  return coreApi.get<Employee>(`${controller}/${id}`);
}

function del(id: string) {
  return coreApi.remove(`${controller}/${id}`);
}

function getContractTypes() {
  return coreApi.get<EmploymentContractType[]>(`${controller}/ContractTypes`, {});
}