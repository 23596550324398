import { useEffect, useState } from "react";
import { PasswordInput } from "./PasswordInput"; // Adjust the import path as necessary
import { Col, Row } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { authApi } from "../auth.api";
import SapIcon from "../../../components/SapIcon";
import { getApiError } from "../../../app/notification-service";

export default function RecoverPassword() {
    const [error, setError] = useState("");
    const [succeeded, setSucceeded] = useState(false);
    const [serverError, setServerError] = useState("");
    const [userId, setUserId] = useState("");
    const [passwordResetToken, setPasswordResetToken] = useState("");

    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setUserId(params.get("userId") || "");
        setPasswordResetToken(params.get("passwordResetToken") || "");

        //set error if required query params are missing
        if (!params.get("userId") || !params.get("passwordResetToken")) {
            setError("Invalid password reset link");
        }
    }, [location.search]);

    const handleSubmit = (password: string) => {
        authApi
            .resetUserPwd({
                userId,
                passwordResetToken,
                password,
            })
            .then((result) => {
                if (result.succeeded) {
                    setSucceeded(true);
                    setServerError("");
                } else {
                    setServerError(`Error resetting password: ${result.errorMessage}`);
                    setSucceeded(false);
                }
            })
            .catch((error) => {
                console.log(error);
                const err = getApiError(error);
                setServerError(err.message);
                setSucceeded(false);
            });
    };

    if (error) {
        return (
            <Row>
                <Col>
                    <h4 className="text-danger">
                        {error}
                        <SapIcon className="ms-2" icon="exclamation-triangle" />
                    </h4>
                </Col>
            </Row>
        );
    }

    if (succeeded) {
        return (
            <Row>
                <Col>
                    <h4 className="text-success">
                        Password reset successfully <SapIcon icon="check" />
                    </h4>
                    <Link to="/account/login">Go to Login</Link>
                </Col>
            </Row>
        );
    }

    return (
        <>
            <Row>
                <Col>
                    <h2>Reset Your Password</h2>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={4}>
                    <PasswordInput onSubmit={handleSubmit} />
                </Col>
            </Row>
            {serverError && (
                <Row>
                    <Col>
                        <p className="text-danger">{serverError}</p>
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Link to="/account/login">Back to Login</Link>
                </Col>
            </Row>
        </>
    );
}
