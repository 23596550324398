import React, { useState } from "react";
import { Form, FormGroup, Label, Input, Button, Alert, Row, Col } from "reactstrap";
import { authApi } from "../auth.api";
import { getApiError } from "../../../app/notification-service";
import { Link } from "react-router-dom";

const ForgetPassword: React.FC = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setError("");
        setMessage("");

        email &&
            authApi
                .requestForgetPassword(email)
                .then(() => setMessage("Instructions to recover your password has been sent to your email."))
                .catch((err) => {
                    const error = getApiError(err);
                    setError(error.message);
                });
    };

    return (
        <div>
            <h2>Recover Password</h2>
            <Row className="mt-4">
                <Col md={4}>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <small className="text-muted">
                                We will send you an email with instructions to recover your password.
                            </small>
                        </FormGroup>
                        <FormGroup>
                            <Button type="submit" color="primary">
                                Submit
                            </Button>
                        </FormGroup>

                        <FormGroup>
                            <Link to="/account/login">Back to Login</Link>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
            {message && (
                <Alert color="success" className="mt-3">
                    {message}
                </Alert>
            )}
            {error && (
                <Alert color="danger" className="mt-3">
                    {error}
                </Alert>
            )}
        </div>
    );
};

export default ForgetPassword;
