import { useEffect, useState } from "react";
import { PasswordInput } from "./PasswordInput"; // Adjust the import path as necessary
import { Col, Row } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { authApi } from "../auth.api";
import SapIcon from "../../../components/SapIcon";
import { getApiError } from "../../../app/notification-service";

export function AccountVerify() {
    const [error, setError] = useState("");
    const [verifySucceeded, setVerifySucceeded] = useState(false);
    const [serverError, setServerError] = useState("");
    const [userId, setUserId] = useState("");
    const [emailConfirmToken, setEmailConfirmToken] = useState("");
    const [passwordResetToken, setPasswordResetToken] = useState("");

    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setUserId(params.get("userId") || "");
        setEmailConfirmToken(params.get("emailConfirmToken") || "");
        setPasswordResetToken(params.get("passwordResetToken") || "");

        //set error if required query params are missing
        if (!params.get("userId") || !params.get("emailConfirmToken") || !params.get("passwordResetToken")) {
            setError("Invalid verification link");
        }
    }, [location.search]);

    const handleSubmit = (password: string) => {
        authApi
            .verifyAccount({
                userId,
                emailConfirmToken,
                passwordResetToken,
                password,
            })
            .then(() => {
                setVerifySucceeded(true);
                setServerError("");
            })
            .catch((error) => {
                console.log(error);

                const err = getApiError(error);

                setServerError(err.message);
                setVerifySucceeded(false);
            });
    };

    if (error) {
        return (
            <Row>
                <Col>
                    <h4 className="text-danger">
                        {error}
                        <SapIcon className="ms-2" icon="exclamation-triangle" />
                    </h4>
                </Col>
            </Row>
        );
    }

    if (verifySucceeded) {
        return (
            <Row>
                <Col>
                    <h4 className="text-success">
                        Account verified successfully <SapIcon icon="check" />
                    </h4>
                    <Link to="/account/login">Go to Login</Link>
                </Col>
            </Row>
        );
    }

    return (
        <>
            <Row>
                <Col>
                    <h2>Welcome to SME Plus</h2>
                </Col>
            </Row>
            <Row className="mt-2 mb-2">
                <Col>
                    <h6>Setup Your Password</h6>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <PasswordInput onSubmit={handleSubmit} />
                </Col>
            </Row>
            {serverError && (
                <Row>
                    <Col>
                        <p className="text-danger">{serverError}</p>
                    </Col>
                </Row>
            )}
        </>
    );
}
