import { ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectAuthUser, setShowTenantSwitcher } from '../auth.slice';
import { subscribedTenantsSelector } from "../../tenant/tenant.slice";
import { switchTenant } from "../auth.api";
import { storageHelper, storageKeys } from "../../../app/storage-helper";
import { useEffect, useState } from "react";

export default function TenantSwitcher() {
    const dispatch = useAppDispatch();
    const showTenantSwitcher = useAppSelector((state) => state.auth.showTenantSwitcher);
    const tenants = useAppSelector(subscribedTenantsSelector);
    const user = useAppSelector(selectAuthUser);
    const [inProgress, setInProgress] = useState(false);

    const hideTenantSwitcher = () => dispatch(setShowTenantSwitcher(false));

    const handleSwitchTenant = (id: string) => {
        if (id != user?.tenantId) {
            setInProgress(true);
            switchTenant(id).then((authToken) => {
                storageHelper.setValue(storageKeys.authToken, authToken.authToken);
                storageHelper.setValue(storageKeys.tenantId, id);
                window.location.replace('/');
            }).finally(() => setInProgress(false));
        } else {
            hideTenantSwitcher();
        }
    }

    useEffect(() => {
        if (!showTenantSwitcher) {
            setInProgress(false);
        }
    }, [showTenantSwitcher])

    return <Modal size='sm' centered
        isOpen={showTenantSwitcher}
        toggle={hideTenantSwitcher}>
        <ModalHeader toggle={hideTenantSwitcher}>
            Switch Business
            {inProgress && <Spinner className="ms-2" size="sm" />}
        </ModalHeader>
        <ModalBody>
            <ListGroup>
                {tenants.map((val) => <ListGroupItem key={val.id}
                    disabled={inProgress}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSwitchTenant(val.id)}
                    color={val.id === user?.tenantId ? 'primary' : ''}>
                    {`${val.businessName} ${val.code ? `[${val.code}]` : ''}`}
                </ListGroupItem>)}
            </ListGroup>
        </ModalBody>
    </Modal>
}