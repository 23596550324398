import { coreApi } from "../../app/core-api";
import { EntityCreateResult, EntityUpdateResult } from "../../app/types";
import { Lookup, LookupEntry, LookupHeader } from "./types";

export function getHeaderByCode(code: string) {
    return coreApi.get<LookupHeader>(`lookups/header/bycode/${code}`);
}

export function getLookupsByHeader(headerCode: string) {
    return coreApi.get<Lookup[]>(`lookups/byheadercode/${headerCode}`);
}

export function createLookup(lookup: LookupEntry) {
    return coreApi.post<EntityCreateResult>('lookups', lookup);
}

export function updateLookup(id: string, lookup: LookupEntry) {
    return coreApi.put<EntityUpdateResult>(`lookups/${id}`, lookup);
}

export function deleteLookup(id: string) {
    return coreApi.remove(`lookups/${id}`);
}

export function getLookup(id: string) {
    return coreApi.get<Lookup>(`lookups/${id}`);
}
