import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { fetchProjects, fetchTransaction, fetchTransactionsSummary, fetchTransactions } from "./finance-api";
import { FinanceState, TransactionFilter } from "./types";
import { getCachedDateRange } from "../../components/SmartDateRangeSelector";

export const ANALYTICS_DATE_FILETER_ID = "analytic_date_filter";
const dateRange = getCachedDateRange(ANALYTICS_DATE_FILETER_ID);

const initialState: FinanceState = {
    projects: [],
    transactions: [],
    totalTxns: 0,
    txnFilter: { from: dateRange?.from, to: dateRange?.to, projects: [], page: 1, pageSize: 25 },
    transactionsSummary: { expenses: 0, income: 0, profit: 0, shareDividend: 0 },
};

export const fetchProjectsListItemsAsync = createAsyncThunk("finance/projects", async () => {
    const projects = await fetchProjects();
    return projects;
});

export const fetchTransactionsAsync = createAsyncThunk("finance/transactions", async (filter: TransactionFilter) => {
    const txns = await fetchTransactions(filter);
    return txns;
});

export const fetchTransactionSummaryAsync = createAsyncThunk(
    "finance/transactions/summary",
    async (filter: TransactionFilter) => {
        const summary = await fetchTransactionsSummary(filter);
        return summary;
    }
);

export const fetchTransactionToEditAsync = createAsyncThunk("finance/editxn", async (id: string) => {
    const txn = await fetchTransaction(id);
    return txn;
});

export const updateEditingTransactionAsync = createAsyncThunk("finance/updateTxnInList", async (id: string) => {
    const txn = await fetchTransaction(id);
    return txn;
});

export const financeSlice = createSlice({
    name: "finance",
    initialState,
    reducers: {
        changeTxnFilter: (state, action: PayloadAction<any>) => {
            state.txnFilter = { ...state.txnFilter, ...action.payload };
        },
        clearEditingTransaction: (state) => {
            state.editingTxn = undefined;
        },
        removeTransactionFromList: (state, action: PayloadAction<string>) => {
            const txnId = action.payload;
            const index = state.transactions.findIndex((t) => t.id == txnId);
            const txns = [...state.transactions];
            txns.splice(index, 1);
            state.transactions = txns;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjectsListItemsAsync.fulfilled, (state, action) => {
                state.projects = action.payload;
            })
            .addCase(fetchTransactionsAsync.fulfilled, (state, action) => {
                state.transactions = action.payload.items;
                state.totalTxns = action.payload.total;
                state.transactionSearchInprogress = false;
            })
            .addCase(fetchTransactionsAsync.pending, (state) => {
                state.transactionSearchInprogress = true;
            })
            .addCase(fetchTransactionsAsync.rejected, (state) => {
                state.transactionSearchInprogress = true;
            })
            .addCase(fetchTransactionSummaryAsync.fulfilled, (state, action) => {
                state.transactionsSummary = action.payload;
            })
            .addCase(fetchTransactionToEditAsync.fulfilled, (state, action) => {
                state.editingTxn = action.payload;
            })
            .addCase(updateEditingTransactionAsync.fulfilled, (state, action) => {
                const txn = action.payload;
                const index = state.transactions.findIndex((t) => t.id == txn.id);
                const txns = [...state.transactions];
                txns.splice(index, 1, txn);
                state.transactions = txns;

                if (state.editingTxn) state.editingTxn = { ...txn };
            });
    },
});

export const { changeTxnFilter, clearEditingTransaction, removeTransactionFromList } = financeSlice.actions;

export const projectsSelector = (state: RootState) => state.finance.projects;
export const hasActiveProjectsSelector = (state: RootState) => state.finance.projects.some((p) => !p.inactive);
export const transactionsSelector = (state: RootState) => state.finance.transactions;
export const transactionsSummarySelector = (state: RootState) => state.finance.transactionsSummary;
export const txnFilterSelector = (state: RootState) => state.finance.txnFilter;
export const editingTxnSelector = (state: RootState) => state.finance.editingTxn;

export default financeSlice.reducer;
