import { coreApi } from "../../app/core-api";
import { EntityCreateResult, EntityUpdateResult, ListItem } from "../../app/types";
import { TenantUser, TenantUserRegisterDto, UserAvailabilityStatus, UserDto, UserRegisterDto } from "./users.types";

const controller = "Users";

export const usersApi = {
    registerTenantUser,
    getTenantRoles,
    checkUserAvailabilityStatus,
    getCurrentUser,
    updateCurrentUser,
    deleteTenantUser,
    getTenantUser,
    updateTenantUser,
};

function registerTenantUser(data: TenantUserRegisterDto) {
    return coreApi.post<EntityCreateResult>(`${controller}/Register/TenantUser`, data);
}

function getTenantRoles() {
    return coreApi.get<ListItem[]>("Users/TenantRoles");
}

function checkUserAvailabilityStatus(email: string) {
    return coreApi.get<UserAvailabilityStatus>(`Users/CheckAvailability/${email}`);
}

function getCurrentUser() {
    return coreApi.get<UserDto>(`Users/currentUser`);
}

function updateCurrentUser(data: UserDto) {
    return coreApi.put<EntityUpdateResult>(`Users/currentUser`, data);
}

function deleteTenantUser(id: string) {
    return coreApi.remove(`${controller}/TenantUser/${id}`);
}

function getTenantUser(id: string) {
    return coreApi.get<TenantUser>(`${controller}/TenantUser/${id}`);
}

function updateTenantUser(id: string, data: UserRegisterDto) {
    return coreApi.put<EntityUpdateResult>(`${controller}/TenantUser/${id}`, data);
}
