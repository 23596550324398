import { Button, Col, FormGroup, Label, Nav, NavItem, NavLink, Popover, PopoverBody, Row, TabContent, TabPane } from "reactstrap";
import { Fragment, useEffect, useMemo, useState } from "react";
import { ListItem } from "../app/types";
import DateSelect2 from "./DateSelect2";
import { dateHelpers } from "../app/helpers";
import { storageHelper, storageKeys } from "../app/storage-helper";
import { useAppSelector } from "../app/hooks";
import { selectAuthUser } from "../features/auth/auth.slice";
import SapIcon from "./SapIcon";

const MODE_RELATIVE = 'relative';
const MODE_CUSTOM = 'custom';
const MODE_MONTH = 'month';

const RELATIVE_DURATIONS: ListItem[] = [
    { key: 'all', value: 'All' },
    { key: 'this_month', value: 'This Month' },
    { key: 'last_month', value: 'Last Month' },
    { key: 'this_year', value: 'This Year' },
    { key: 'last_year', value: 'Last Year' }
]

interface DateRangeStorageItem {
    mode: 'custom' | 'relative' | 'month';
    relativeRange?: string;
    customRange?: DateRange;
    definedRangeStartDate?: string;
}

interface CachedDateRangeStorageItem {
    entry: DateRangeStorageItem;
    dateRange: DateRange;
}

interface DateRange {
    from?: string;
    to?: string;
}

interface Props {
    id: string;
    onChange: (range: DateRange) => void;
}

function getMonthDateRange(month: string): DateRange {
    const startDate = new Date(month);
    const fromDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
    const toDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);

    return {
        from: dateHelpers.toIsoString(fromDate),
        to: dateHelpers.toIsoString(toDate)
    }
}

function getDefinedRangeDisplayText(startDate: string) {
    return dateHelpers.formatDate(startDate, "MMM YYYY");
}

function getRelativeDateRange(range: string): DateRange {
    let fromDate: Date | undefined;
    let toDate: Date | undefined;

    const date = new Date();

    switch (range) {
        case 'this_month': {
            fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
            toDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            break;
        }
        case 'last_month': {
            fromDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
            toDate = new Date(date.getFullYear(), date.getMonth(), 0);
            break;
        }
        case 'this_year': {
            fromDate = new Date(date.getFullYear(), 0, 1);
            toDate = new Date(date.getFullYear(), 11, 31);
            break;
        }
        case 'last_year': {
            fromDate = new Date(date.getFullYear() - 1, 0, 1);
            toDate = new Date(date.getFullYear() - 1, 11, 31);
            break;
        }
    }

    return {
        from: fromDate && dateHelpers.toIsoString(fromDate),
        to: toDate && dateHelpers.toIsoString(toDate)
    }
}

export function getCachedDateRange(smartDateRangeCtrlId: string) {
    const tenantId = storageHelper.getValue(storageKeys.tenantId) || '';    
    if(tenantId) {
        const storageKey = `${tenantId}_${smartDateRangeCtrlId}`;
        const cachedFilter = getSmartDateRangeCached(storageKey);

        if(cachedFilter) {
            const entry = cachedFilter.entry;

            if(entry.relativeRange) return getRelativeDateRange(entry.relativeRange);

            if(entry.customRange && Object.keys(entry.customRange).length > 0) return entry.customRange;

            if(entry.definedRangeStartDate) return getMonthDateRange(entry.definedRangeStartDate);
        }
    }    
    return undefined;
}

export function getSmartDateRangeCached(storageKey: string) {
    const savedFilter = storageHelper.getValue(storageKey);
    if (savedFilter) {
        const entry = JSON.parse(savedFilter) as DateRangeStorageItem;

        const dateRange = entry.mode == MODE_RELATIVE ? getRelativeDateRange(entry.relativeRange!)
            : entry.mode == MODE_MONTH && entry.definedRangeStartDate ? getMonthDateRange(entry.definedRangeStartDate)
                : (entry.customRange || {});

        const cachedFilter: CachedDateRangeStorageItem = { entry, dateRange };

        return cachedFilter;
    }

    return undefined
}

export default function SmartDateRangeSelector({ id, onChange }: Props) {
    const [isOpen, setIsOpen] = useState(false);

    const [activeMode, setActiveMode] = useState<string>();
    const [relativeRange, setRelativeRange] = useState<string>();
    const [customDateRange, setCustomDateRange] = useState<DateRange>({});
    const [definedRangeStartDate, setDefinedRangeStartDate] = useState<string>();

    const user = useAppSelector(selectAuthUser);

    const storageKey = useMemo(() => {
        if (user?.tenantId) {
            return `${user?.tenantId}_${id}`;
        }
    }, [user?.tenantId]);

    useEffect(() => {
        const savedFilter = storageKey ? getSmartDateRangeCached(storageKey) : undefined;
        if (savedFilter) {
            const entry = savedFilter.entry;

            setActiveMode(entry.mode);
            entry.relativeRange && setRelativeRange(entry.relativeRange);
            entry.customRange && setCustomDateRange({ ...entry.customRange });
            entry.definedRangeStartDate && setDefinedRangeStartDate(entry.definedRangeStartDate);

            onChange(savedFilter.dateRange);
        } else {
            setActiveMode(MODE_RELATIVE);
            setRelativeRange('all');

            onChange(getRelativeDateRange('all'));
        }
    }, [storageKey])

    const toggle = () => setIsOpen(!isOpen);

    const handleRelativeSelect = (range: string) => {
        setRelativeRange(range);
        setCustomDateRange({});
        setDefinedRangeStartDate(undefined);

        saveInStorage(MODE_RELATIVE, range)

        const dateRange = getRelativeDateRange(range);
        onChange(dateRange);

        toggle();
    }

    const handleCustomRangeSelect = () => {
        setRelativeRange(undefined);
        setDefinedRangeStartDate(undefined);

        saveInStorage(MODE_CUSTOM, undefined, customDateRange || {});

        onChange(customDateRange || {});

        toggle();
    }

    const handleMonthSelect = (date?: string, preventToggle?: boolean) => {
        setDefinedRangeStartDate(date);
        setRelativeRange(undefined);
        setCustomDateRange({});

        saveInStorage(date ? MODE_MONTH : MODE_CUSTOM, undefined, {}, date);

        onChange(date && getMonthDateRange(date) || {});

        !preventToggle && toggle();
    }

    const saveInStorage = (mode: 'custom' | 'relative' | 'month',
        relativeRange?: string,
        customRange?: DateRange, definedRangeStartDate?: string) => {

        const entry: DateRangeStorageItem = {
            mode,
            relativeRange,
            customRange,
            definedRangeStartDate
        };

        storageKey && storageHelper.setValue(storageKey, JSON.stringify(entry));
    }

    const traverseMonthForward = () => traverseMonth(1);

    const traverseMonthBackward = () => traverseMonth(-1);

    const traverseMonth = (increment: number) => {
        if (definedRangeStartDate) {
            const date = new Date(definedRangeStartDate);
            const newDate = new Date(date.getFullYear(), date.getMonth() + increment, 1);
            const newDateStr = dateHelpers.toIsoString(newDate);

            handleMonthSelect(newDateStr, true);
        }
    }

    const isFixedRelativeRange = definedRangeStartDate;

    return <>
        <div className="text-nowrap" style={{ cursor: 'pointer' }}>
            <SapIcon icon="clock" />
            {isFixedRelativeRange &&
                <SapIcon icon="chevron-left" mode="button" className="ms-2" onClick={traverseMonthBackward} />}
            <span id="date_range_popover_div" className={`ms-1`}>
                <u>
                    {relativeRange ? RELATIVE_DURATIONS.find(d => d.key == relativeRange)?.value
                        : definedRangeStartDate ? getDefinedRangeDisplayText(definedRangeStartDate)
                            : `${customDateRange.from && dateHelpers.toShortDateString(customDateRange.from) || '*'}-${customDateRange.to && dateHelpers.toShortDateString(customDateRange.to) || "*"}`}
                </u>
            </span>
            {isFixedRelativeRange &&
                <SapIcon icon="chevron-right" mode="button" className="ms-1" onClick={traverseMonthForward} />}
        </div>
        <Popover isOpen={isOpen}
            placement="bottom"
            target="date_range_popover_div"
            toggle={toggle}
        >
            <PopoverBody>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={activeMode == MODE_RELATIVE ? 'active' : ''}
                            onClick={() => setActiveMode(MODE_RELATIVE)}
                        >
                            Relative
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeMode == MODE_MONTH ? 'active' : ''}
                            onClick={() => setActiveMode(MODE_MONTH)}
                        >
                            Month
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeMode == MODE_CUSTOM ? 'active' : ''}
                            onClick={() => setActiveMode(MODE_CUSTOM)}
                        >
                            Custom
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeMode} style={{ width: '200px' }}>
                    <TabPane tabId={MODE_RELATIVE}>
                        <Row>
                            <Col sm="12">
                                {
                                    RELATIVE_DURATIONS.map(d => <Fragment key={d.key} >
                                        <Button color="link" onClick={() => handleRelativeSelect(d.key)}>
                                            {d.value}<span>{d.key == relativeRange ? " *" : ""}</span>
                                        </Button>
                                        <br />
                                    </Fragment>)
                                }
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId={MODE_MONTH}>
                        <Row>
                            <Col sm="12" className="pt-3">
                                <FormGroup>
                                    <DateSelect2
                                        dateFormat="MMM yyyy"
                                        value={definedRangeStartDate}
                                        onChange={handleMonthSelect}
                                        monthPicker />
                                </FormGroup>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId={MODE_CUSTOM}>
                        <Row className="pt-3">
                            <Col sm="12">
                                <FormGroup>
                                    <Label>From</Label>
                                    <Col>
                                        <DateSelect2 value={customDateRange?.from} onChange={(from) => setCustomDateRange({ ...customDateRange, from })} isClearable />
                                    </Col>
                                </FormGroup>
                                <FormGroup>
                                    <Label>To</Label>
                                    <DateSelect2 value={customDateRange?.to} onChange={(to) => setCustomDateRange({ ...customDateRange, to })} isClearable />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button color="primary" size="sm" onClick={handleCustomRangeSelect}>Apply</Button>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </PopoverBody>
        </Popover>
    </>
}