import { coreApi } from "../../app/core-api";
import {
    AccountVerifyDto,
    AuthResult,
    ChangePasswordDto,
    ChangePasswordResult,
    ResetPasswordDto,
    LoginCredentials,
} from "./auth.types";

export const authApi = {
    login,
    switchTenant,
    changePassword,
    forceResetUserPwd,
    requestDeleteAccount,
    verifyAccount,
    requestForgetPassword,
    resetUserPwd,
};

export function login(credentials: LoginCredentials) {
    return coreApi.post<AuthResult>("users/login", credentials);
}

export function switchTenant(tenantId?: string) {
    return coreApi.post<AuthResult>("users/SwitchTenant", { tenantId });
}

export function changePassword(data: ChangePasswordDto) {
    return coreApi.post<ChangePasswordResult>("users/changepassword", data);
}

export function forceResetUserPwd(data: ResetPasswordDto) {
    return coreApi.post<ChangePasswordResult>(`users/ForceResetPassword`, data);
}

export function resetUserPwd(data: ResetPasswordDto) {
    return coreApi.post<ChangePasswordResult>(`users/ResetPassword`, data);
}

export function requestDeleteAccount() {
    return coreApi.post<String>("users/DeleteMyAccount", {});
}

function verifyAccount(data: AccountVerifyDto) {
    return coreApi.post("users/VerifyAccount", data);
}

function requestForgetPassword(email: string) {
    return coreApi.post(`users/RecoverPassword/${email}`, {});
}
