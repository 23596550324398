import { coreApi } from "../../app/core-api";
import { EntityCreateResult, EntityUpdateResult, IDictionary, ListItem } from "../../app/types";
import {
    Tenant,
    TenantCompactListItem,
    TenantCreateDto,
    TenantFullListItem,
    TenantRegisterDto,
    TenantUpdateDto,
} from "./tenant.types";

export const tenantApi = {
    getTenant,
    updateFeatures,
    setPrimaryContact,
    deleteTenant,
    getCurrentTenant,
    getTenants,
    updateTenant,
    createTenant,
    getAllFeatures,
    getSubscribedTenants,
    getCurrentTenantFeatures,
    updateCurrentTenant,
    getAllTenants,
    getNatureOfBusinesses,
    registerTenant,
};

function getTenants() {
    return coreApi.get<Tenant[]>("Tenants/CurrentCompany/All");
}

function getAllTenants() {
    return coreApi.get<TenantFullListItem[]>("Tenants/All");
}

function getCurrentTenant() {
    return coreApi.get<Tenant>("Tenants/Current");
}

function getTenant(id: string) {
    return coreApi.get<Tenant>(`Tenants/${id}`);
}

function getSubscribedTenants() {
    return coreApi.get<TenantCompactListItem[]>("Tenants/Subscribed");
}

function getCurrentTenantFeatures() {
    return coreApi.get<string[]>("Subscription/Features");
}

function updateTenant(id: string, tenant: TenantUpdateDto) {
    return coreApi.put<EntityUpdateResult>(`Tenants/${id}`, tenant);
}

function updateFeatures(id: string, features: string[]) {
    return coreApi.put<EntityUpdateResult>(`Tenants/${id}/Features`, features);
}

function updateCurrentTenant(tenant: TenantUpdateDto) {
    return coreApi.put<EntityUpdateResult>(`Tenants/Current`, tenant);
}

function createTenant(tenant: TenantCreateDto) {
    return coreApi.post<EntityCreateResult>(`Tenants`, tenant);
}

function registerTenant(tenant: TenantRegisterDto) {
    return coreApi.post<EntityCreateResult>(`Tenants`, tenant);
}

function deleteTenant(id: string) {
    return coreApi.remove(`Tenants/${id}`);
}

function getAllFeatures() {
    return coreApi.get<IDictionary<ListItem[]>>(`Subscription/AllFeatures`);
}

function getNatureOfBusinesses() {
    return coreApi.get<ListItem[]>("Tenants/NatureOfBusinesses");
}

function setPrimaryContact(tenantId: string, userId: string) {
    return coreApi.put(`Tenants/${tenantId}/PrimaryContact/${userId}`, {});
}
