import { Button, Spinner } from "reactstrap";

interface Props {
    disabled?: boolean;
    children: string;
    type?: "submit" | "reset" | "button";
    color?: string;
    className?: string;
    submitting?: boolean;
    size?: string;
    title?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function SapButton({
    disabled,
    children,
    type,
    color,
    className,
    onClick,
    submitting,
    size,
    title,
}: Props) {
    return (
        <Button
            className={`min-width-btn ${className || ""}`}
            type={type || "button"}
            size={size}
            disabled={disabled || submitting}
            color={color}
            onClick={onClick}
            title={title}
        >
            {submitting && <Spinner className="me-2" size="sm" />}
            {children}
        </Button>
    );
}
