import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { UsersState } from "./users.types";
import { usersApi } from "./users.api";

const initialState: UsersState = {};

export const fetchTenantRolesAsync = createAsyncThunk("users/tenantRoles", async () => {
    const roles = await usersApi.getTenantRoles();
    return roles;
});

export const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTenantRolesAsync.fulfilled, (state, action) => {
            state.tenantRoles = action.payload;
        });
    },
});

export const {} = usersSlice.actions;

export const tenantRolesSelector = (state: RootState) => state.users.tenantRoles;

export default usersSlice.reducer;
