import { Link } from "react-router-dom";
import { selectAuthUser, setShowTenantSwitcher } from "../features/auth/auth.slice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useEffect, useState } from "react";
import { subscribedTenantsSelector } from "../features/tenant/tenant.slice";
import { TenantCompactListItem } from "../features/tenant/tenant.types";
import { Spinner } from "reactstrap";
import { RootState } from "../app/store";
import { authService } from "../features/auth/auth.service";

export default function TopNav() {
    const dispatch = useAppDispatch();

    const user = useAppSelector(selectAuthUser);
    const tenants = useAppSelector(subscribedTenantsSelector);
    const loadingSubscribedTenants = useAppSelector((state: RootState) => state.tenant.loadingSubscribedTenants);

    const [currentTenant, setCurrentTentnt] = useState<TenantCompactListItem>();

    useEffect(() => {
        if (user && tenants.length) {
            const tenant = tenants.find((t) => t.id == user?.tenantId);
            setCurrentTentnt(tenant);
        }
    }, [user, tenants]);

    const handleLogout = () => {
        authService.clearAuthToken();
        window.location.replace("/account/login");
    };

    const toggleSideNav = (event: any) => {
        event.preventDefault();
        document.body.classList.toggle("sb-sidenav-toggled");
        const nav = document.body.classList.contains("sb-sidenav-toggled");
        localStorage.setItem("sb|sidebar-toggle", nav.toString());
    };

    const getTenantCode = () => {
        var code = currentTenant?.code || currentTenant?.businessName?.substring(0, 2).toUpperCase() || "";
        return code;
    };

    return (
        <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
            {/* <!-- Navbar Brand--> */}
            <Link className="navbar-brand text-center" to="/">
                <img src="/logo.png" alt="logo" height="38" />
            </Link>
            {/* <!-- Sidebar Toggle--> */}
            <button
                className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
                id="sidebarToggle"
                onClick={toggleSideNav}
            >
                <i className="fas fa-bars"></i>
            </button>
            {/* <div className="d-none d-md-inline-block form-inline ms-auto ms-md-0 me-md-3 my-2 my-md-0"></div> */}
            <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0"></form>
            {currentTenant ? (
                <span
                    className="text-muted ms-auto"
                    style={{ cursor: "pointer" }}
                    title={currentTenant?.businessName}
                    onClick={() => dispatch(setShowTenantSwitcher(true))}
                >
                    {`[${getTenantCode()}]`}
                </span>
            ) : loadingSubscribedTenants ? (
                <Spinner color="secondary" size="sm" />
            ) : null}
            <ul className="navbar-nav  ms-md-0 me-3 me-lg-4">
                <li className="nav-item dropdown">
                    <a
                        className="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <i className="fas fa-user fa-fw"></i>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        <li>
                            <Link className="dropdown-item" to="/user/profile">
                                My Profile
                            </Link>
                        </li>
                        <li>
                            <a className="dropdown-item" href="#" onClick={() => dispatch(setShowTenantSwitcher(true))}>
                                Switch Business
                            </a>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <li>
                            <a className="dropdown-item" href="#" onClick={handleLogout}>
                                Logout
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
}
