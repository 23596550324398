import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import SapLoading from "../components/SapLoading";
import { Footer, TopNav, MainContent, SideNav } from "./index";
import {
    fetchFeaturesAsync,
    selectAuthUser,
    selectFeatures,
    setAuthInitialized,
    setAuthUser,
} from "../features/auth/auth.slice";
import { setAppInitialized, setIsMobile } from "../app/core-slice";
import { authService } from "../features/auth/auth.service";
import { fetchProjectsListItemsAsync } from "../features/finance/finance-slice";
import { fetchSubscribedTenantsAsync } from "../features/tenant/tenant.slice";
import PrivateRoute from "../components/PrivateRoute";

function detectMobile() {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

export default function MainLayout() {
    const appInitialized = useAppSelector((state) => state.core.appInitialized);

    const user = useAppSelector(selectAuthUser);
    const authInitialized = useAppSelector((state) => state.auth.initialized);
    const features = useAppSelector(selectFeatures);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setIsMobile(detectMobile()));

        const user = authService.extractAuthUserFromToken();
        dispatch(setAuthUser(user));
        dispatch(setAuthInitialized(true));
    }, []);

    useEffect(() => {
        if (user) {
            dispatch(fetchFeaturesAsync());
            loadCommonData();
        }
    }, [user]);

    useEffect(() => {
        if ((authInitialized && !user) || (authInitialized && user && features)) dispatch(setAppInitialized(true));
    }, [authInitialized, user, features]);

    const loadCommonData = () => {
        dispatch(fetchProjectsListItemsAsync());
        dispatch(fetchSubscribedTenantsAsync());
    };

    if (!appInitialized) return <SapLoading />;

    return (
        <>
            <TopNav />
            <div id="layoutSidenav">
                <SideNav />
                <div id="layoutSidenav_content">
                    <PrivateRoute>
                        <MainContent />
                    </PrivateRoute>
                    <Footer />
                </div>
            </div>
        </>
    );
}
