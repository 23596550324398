import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LeaveFilter, LeaveRequestFilter, LeaveState } from "./leave.types";
import { RootState } from "../../app/store";
import { leaveApi } from "./leave.api";
import { PagedSearchFilter } from "../../app/types";

const initialState: LeaveState = {
    employeeLeaves: {
        items: [],
        total: 0,
    },
    employeeLeavesFilter: {
        year: new Date().getFullYear(),
        searchTerm: "",
    },
    leaveApprovalsFilter: {
        searchTerm: "",
    },
};

export const fetchLeaveTypesListAsync = createAsyncThunk("leave/leaveTypes", async () => {
    const leaveTypes = await leaveApi.listLeaveTypes();
    return leaveTypes;
});

export const fetchEmployeeLeavesAsync = createAsyncThunk(
    "leave/employeeLeaves",
    async (filter: PagedSearchFilter<LeaveFilter>) => {
        const employeeLeaves = await leaveApi.searchEmployeeLeave(filter);
        return employeeLeaves;
    }
);

export const fetchLeaveTypeSelectListAsync = createAsyncThunk("leave/leaveTypesSelectList", async () => {
    const leaveTypes = await leaveApi.getLeaveTypesSelectList();
    return leaveTypes;
});

export const fetchLeaveApprovalsAsync = createAsyncThunk(
    "leave/leaveRequestsToApprove",
    async (filter: PagedSearchFilter<LeaveRequestFilter>) => {
        const leaveRequests = await leaveApi.getLeaveApprovals(filter);
        return leaveRequests;
    }
);

export const leaveSlice = createSlice({
    name: "leave",
    initialState,
    reducers: {
        leaveFilterChange: (state, action) => {
            state.employeeLeavesFilter = { ...state.employeeLeavesFilter, ...action.payload };
        },
        leaveApprovalsFilterChange: (state, action) => {
            state.leaveApprovalsFilter = { ...state.leaveApprovalsFilter, ...action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLeaveTypesListAsync.fulfilled, (state, action) => {
                state.leaveTypes = action.payload;
            })
            .addCase(fetchEmployeeLeavesAsync.fulfilled, (state, action) => {
                state.employeeLeaves = action.payload;
                state.employeeLeavesSeachInprogress = false;
            })
            .addCase(fetchEmployeeLeavesAsync.pending, (state) => {
                state.employeeLeavesSeachInprogress = true;
            })
            .addCase(fetchEmployeeLeavesAsync.rejected, (state) => {
                state.employeeLeavesSeachInprogress = false;
            })
            .addCase(fetchLeaveTypeSelectListAsync.fulfilled, (state, action) => {
                state.leaveTypesSelectList = action.payload;
            })
            .addCase(fetchLeaveApprovalsAsync.fulfilled, (state, action) => {
                state.leaveApprovals = action.payload;
                state.leaveApprovalsSearchInprogress = false;
            })
            .addCase(fetchLeaveApprovalsAsync.pending, (state) => {
                state.leaveApprovalsSearchInprogress = true;
            })
            .addCase(fetchLeaveApprovalsAsync.rejected, (state) => {
                state.leaveApprovalsSearchInprogress = false;
            });
    },
});

export const { leaveFilterChange, leaveApprovalsFilterChange } = leaveSlice.actions;

export const leaveTypesListSelector = (state: RootState) => state.leave.leaveTypes;
export const employeeLeavesSelector = (state: RootState) => state.leave.employeeLeaves;
export const employeeLeavesFilterSelector = (state: RootState) => state.leave.employeeLeavesFilter;
export const leaveTypesSelectListSelector = (state: RootState) => state.leave.leaveTypesSelectList;
export const leaveRequestsToApproveSelector = (state: RootState) => state.leave.leaveApprovals;
export const leaveRequestsToApproveFilterSelector = (state: RootState) => state.leave.leaveApprovalsFilter;

export default leaveSlice.reducer;
