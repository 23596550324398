import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

interface Props {
    onSubmit: (password: string) => void;
}

export function PasswordInput({ onSubmit }: Props) {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");

    const validatePassword = (pwd: string) => {
        const hasUpperCase = /[A-Z]/.test(pwd);
        const hasLowerCase = /[a-z]/.test(pwd);
        const hasNumber = /[0-9]/.test(pwd);
        return hasUpperCase && hasLowerCase && hasNumber;
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const pwd = e.target.value;
        setPassword(pwd);
        if (!validatePassword(pwd)) {
            setError("Password must contain at least one uppercase letter, one lowercase letter, and one number.");
        } else {
            setError("");
        }
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
        } else if (!validatePassword(password)) {
            setError("Password must contain at least one uppercase letter, one lowercase letter, and one number.");
        } else {
            setError("");
            onSubmit(password);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Label htmlFor="password">Password:</Label>
                <Input type="password" id="password" value={password} onChange={handlePasswordChange} required />
                <small className="text-muted">
                    Password must contain at least one uppercase letter, one lowercase letter, and one number.
                </small>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="confirmPassword">Confirm Password:</Label>
                <Input
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    required
                />
            </FormGroup>
            {error && <p className="text-danger">{error}</p>}
            <FormGroup>
                <Button color="primary" type="submit">
                    Submit
                </Button>
            </FormGroup>
        </Form>
    );
}
