import DateSelect from "./DateSelect";
import { dateHelpers } from "../app/helpers";

interface Props {
    value?: string;
    onChange: (value: string) => void;
    placeholder?: string;
    isClearable?: boolean;
    disabled?: boolean;
    timeSelect?: boolean;
    monthPicker?: boolean;
    yearPicker?: boolean;
    dateFormat?: string;
    showTimeSelectOnly?: boolean;
    minDate?: Date;
    maxDate?: Date;
    className?: string;
    excludeDates?: Date[];
    excludeWeekDays?: number[];
}

export default function DateSelect2({
    value,
    onChange,
    placeholder,
    isClearable,
    disabled,
    yearPicker,
    monthPicker,
    dateFormat,
    timeSelect,
    showTimeSelectOnly,
    minDate,
    maxDate,
    className,
    excludeDates,
    excludeWeekDays,
}: Props) {
    return (
        <DateSelect
            value={value ? dateHelpers.toDate(value) : undefined}
            dateFormat={dateFormat}
            placeholder={placeholder}
            isClearable={isClearable}
            disabled={disabled}
            timeSelect={timeSelect}
            yearPicker={yearPicker}
            monthPicker={monthPicker}
            showTimeSelectOnly={showTimeSelectOnly}
            minDate={minDate}
            maxDate={maxDate}
            className={className}
            onChange={(d) => onChange && onChange(dateHelpers.toIsoString(d, timeSelect))}
            excludeDates={excludeDates}
            excludeWeekDays={excludeWeekDays}
        />
    );
}
