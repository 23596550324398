import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { isMobileSelector } from "../app/core-slice";
import { selectAuthUser, selectFeatures } from "../features/auth/auth.slice";
import { subscribedTenantsSelector } from "../features/tenant/tenant.slice";
import { useEffect, useMemo, useState } from "react";
import { MAIN_NAV, NavElement, NavModule } from "./nav";

interface NavSectionProps {
    module: NavModule;
}

function NavSection({ module }: NavSectionProps) {
    return (
        <>
            <div className="sb-sidenav-menu-heading">{module.name}</div>
            {module.elements.map((sn) => (
                <NavLinkGroup element={sn} key={sn.id} />
            ))}
        </>
    );
}

interface NavGroupProps {
    element: NavElement;
}

function NavLinkGroup({ element }: NavGroupProps) {
    if (element.subElements && element.subElements.length) {
        return (
            <>
                <a
                    className="nav-link collapsed"
                    href="#"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${element.id}`}
                    aria-expanded="false"
                    aria-controls={element.id}
                >
                    <div className="sb-nav-link-icon">
                        <i className={`fas ${element.icon}`} />
                    </div>
                    {element.name}
                    <div className="sb-sidenav-collapse-arrow">
                        <i className="fas fa-angle-down" />
                    </div>
                </a>
                <div
                    className="collapse"
                    id={element.id}
                    aria-labelledby="headingOne"
                    data-bs-parent="#sidenavAccordion"
                >
                    <nav className="sb-sidenav-menu-nested nav">
                        {element.subElements.map((subElement) => (
                            <NavLinkGroup element={subElement} key={subElement.id} />
                        ))}
                    </nav>
                </div>
            </>
        );
    }

    return <NavLinks item={element} />;
}

interface NavLinkProps {
    item: NavElement;
}

function NavLinks({ item }: NavLinkProps) {
    const isMobile = useAppSelector(isMobileSelector);
    const location = useLocation();

    const isActivePath = (path: string) => {
        return location.pathname.toLowerCase() == path.toLowerCase();
    };

    const toggleSideNav = () => {
        isMobile && document.body.classList.toggle("sb-sidenav-toggled");
    };

    return (
        <Link
            className={`nav-link ${isActivePath(item.absolutePath!) ? "active" : ""}`}
            to={item.absolutePath!}
            onClick={toggleSideNav}
        >
            <div className="sb-nav-link-icon">
                <i className={`fas ${item.icon}`} />
            </div>
            {item.name}
        </Link>
    );
}

export default function SideNav() {
    const isMobile = useAppSelector(isMobileSelector);
    const user = useAppSelector(selectAuthUser);
    const tenants = useAppSelector(subscribedTenantsSelector);
    const features = useAppSelector(selectFeatures);
    const [currentTenant, setCurrentTentnt] = useState<string>();

    useEffect(() => {
        if (user && tenants.length) {
            const tenant = tenants.find((t) => t.id == user?.tenantId);
            setCurrentTentnt(tenant?.businessName);
        }
    }, [user, tenants]);

    const toggleSideNav = () => {
        isMobile && document.body.classList.toggle("sb-sidenav-toggled");
    };

    const getEligibleNavElements = (nav: NavElement[], parentName: string, parentPath: string) => {
        const eligibleNavs = nav.filter(
            (n) =>
                !n.hideInMenu &&
                (!n.feature || features?.includes(n.feature)) &&
                (n.allowAnonymous ||
                    (!n.permission && !!user) ||
                    (user && user.permissions.includes(n.permission || "")))
        );

        eligibleNavs.forEach((nav) => {
            nav.id = `${parentName}_${nav.name}`.replaceAll(" ", "_");
            nav.absolutePath = `${parentPath}/${nav.path}`;
            if (nav.subElements) nav.subElements = getEligibleNavElements(nav.subElements, nav.id, nav.absolutePath);
        });

        const filteredNavs = eligibleNavs.filter((n) => !n.subElements || n.subElements.length > 0);
        return filteredNavs;
    };

    const nav = useMemo(() => {
        const eligibleFeatures = [...MAIN_NAV].filter(
            (n) => !n.hideInMenu && (!n.feature || features?.includes(n.feature))
        );
        const menu = eligibleFeatures.map((nav) => {
            nav.elements = getEligibleNavElements(nav.elements, nav.name, `/${nav.path}`);
            return nav;
        });
        return menu.filter((n) => n.elements.length > 0);
    }, [features, user]);

    return (
        <div id="layoutSidenav_nav">
            <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                <div className="sb-sidenav-menu">
                    <div className="nav">
                        {nav.map((n, i) => (
                            <NavSection module={n} key={i} />
                        ))}
                    </div>
                </div>
                {user && (
                    <div className="sb-sidenav-footer">
                        <div className="small">Logged in as:</div>
                        {`${user.givenName}${tenants.length > 1 ? ` | ${currentTenant}` : ""}`}
                    </div>
                )}
            </nav>
        </div>
    );
}
