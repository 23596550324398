import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HarvestLogFilter, HarvestLogState } from "./types";
import { getHarvestLogs, getHarvestSummary } from "./harvestlog-api";
import { RootState } from "../../app/store";
import { getCachedDateRange } from "../../components/SmartDateRangeSelector";

export const HARVEST_DATE_FILETER_ID = "harvestlog_date_filter";
const dateRange = getCachedDateRange(HARVEST_DATE_FILETER_ID);

const initialState: HarvestLogState = {
  searchResult: {
    items: [],
    total: 0
  },
  filter: {
    from: dateRange?.from,
    to: dateRange?.to,
    includeInactiveProjects: false,
    page: 1,
    pageSize: 25
  }
}

export const searchHarvestLogsAsync = createAsyncThunk(
  'harvestLog/search',
  async (filter: HarvestLogFilter) => {
    return getHarvestLogs(filter);
  }
);

export const searchHarvestSummaryAsync = createAsyncThunk(
  'harvestLog/summary',
  async (filter: HarvestLogFilter) => {
    return getHarvestSummary(filter);
  }
);

export const harvestlogSlice = createSlice({
  name: 'harvestlog',
  initialState,
  reducers: {
    changeHarvestLogFilter: (state, action: PayloadAction<any>) => {
      state.filter = { ...state.filter, ...action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(searchHarvestLogsAsync.fulfilled, (state, action) => {
      state.searchResult = action.payload;
      state.searchInprogress = false;
    }).addCase(searchHarvestLogsAsync.pending, (state, action) => {
      state.searchInprogress = true;
    }).addCase(searchHarvestLogsAsync.rejected, (state, action) => {
      state.searchInprogress = false;
    }).addCase(searchHarvestSummaryAsync.fulfilled, (state, action) => {
      state.harvestLogSummary = action.payload;
    })
  }
})

export default harvestlogSlice.reducer;

export const { changeHarvestLogFilter } = harvestlogSlice.actions;

export const harvestlogSelector = (state: RootState) => state.harvest.searchResult.items;
export const harvestlogTotalSelector = (state: RootState) => state.harvest.searchResult.total;
export const harvestlogFilterSelector = (state: RootState) => state.harvest.filter;
export const harvestlogSummarySelector = (state: RootState) => state.harvest.harvestLogSummary;
export const harvestlogSearchStateSelector = (state: RootState) => state.harvest.searchInprogress;
