import { coreApi } from "../../app/core-api";
import { EntityCreateResult, PagedResult } from "../../app/types";
import { PayrollFilter, PayrollGenerateData, PayrollListItem } from "./payroll.types";

export const payrollApi = {
    generate,
    regenerate,
    search,
    disburse,
    checkAvailability
}

const controller = 'payrolls';

function generate(data: PayrollGenerateData) {
    return coreApi.post<EntityCreateResult>(`${controller}/generate`, data);
}

function regenerate(id: string) {
    return coreApi.post<EntityCreateResult>(`${controller}/generate/${id}`, {});
}

function search(filter: PayrollFilter) {
    return coreApi.get<PagedResult<PayrollListItem>>(controller, filter);
}

function disburse(id: string, disburse: boolean) {
    return coreApi.post(`${controller}/${id}/disburse?disbursed=${disburse}`, {});
}

function checkAvailability() {
    return coreApi.get<PayrollGenerateData>(`${controller}/checkAvailability`);
}
