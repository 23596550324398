import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { getCachedDateRange } from "../../components/SmartDateRangeSelector";
import { PayrollFilter, PayrollState } from "../payroll/payroll.types";
import { payrollApi } from "../payroll/payroll.api";

export const HARVEST_DATE_FILETER_ID = "harvestlog_date_filter";
const dateRange = getCachedDateRange(HARVEST_DATE_FILETER_ID);

const initialState: PayrollState = {
  searchResult: {
    items: [],
    total: 0
  },
  filter: {
    from: dateRange?.from,
    to: dateRange?.to,
    page: 1,
    pageSize: 12
  }
}

export const searchPayrollsAsync = createAsyncThunk(
  'payroll/search',
  async (filter: PayrollFilter, { rejectWithValue }) => {
    try {
      return await payrollApi.search(filter);
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const payrollSlice = createSlice({
  name: 'payroll',
  initialState,
  reducers: {
    changePayrollFilter: (state, action: PayloadAction<any>) => {
      state.filter = { ...state.filter, ...action.payload }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(searchPayrollsAsync.fulfilled, (state, action) => {
      state.searchResult = action.payload;
      state.searchInprogress = false;
    }).addCase(searchPayrollsAsync.pending, (state, action) => {
      state.searchInprogress = true;
    }).addCase(searchPayrollsAsync.rejected, (state, action) => {
      state.searchInprogress = false;
    })
  }
})

export default payrollSlice.reducer;

export const { changePayrollFilter } = payrollSlice.actions;

export const payrollsSelector = (state: RootState) => state.payroll.searchResult.items;
export const payrollsTotalSelector = (state: RootState) => state.payroll.searchResult.total;
export const payrollsFilterSelector = (state: RootState) => state.payroll.filter;
export const payrollsSearchStateSelector = (state: RootState) => state.payroll.searchInprogress;
