import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { fetchDashboardData } from "./dashboard-api";
import { DashboardDataItem, DashboardFilter, DashboardState, DashboardView } from "./types";
import { IDictionary } from "../../app/types";
import { getCachedDateRange } from "../../components/SmartDateRangeSelector";

export const DASHBOARD_DATE_FILETER_ID = "dashboard_date_filter";
const dateRange = getCachedDateRange(DASHBOARD_DATE_FILETER_ID);

const initialState: DashboardState = {
  filter: {
    view: DashboardView.Business,
  },
  data: { from: dateRange?.from, to: dateRange?.to, items: [], projects: [] },
  projectData: {},
  businessData: []
}

export const fetchDashboardDataAsync = createAsyncThunk(
  'dashboard/data',
  async (filter: DashboardFilter) => {
    const data = await fetchDashboardData(filter.from, filter.to);
    return data;
  }
)

export const dashboardSlice = createSlice(
  {
    name: 'dashboard',
    initialState,
    reducers: {
      changeDashboardFilter: (state, action: PayloadAction<DashboardFilter>) => {
        state.filter = { ...state.filter, ...action.payload };
      }
    },
    extraReducers: (builder) => {
      builder.addCase(fetchDashboardDataAsync.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;

        const projectDataArray = action.payload.projects.map(project => ([
          project.key, {
            name: project.value,
            from: action.payload.from,
            to: action.payload.to,
            items: action.payload.items.filter(i => i.projectId === project.key)
          }
        ]));

        state.projectData = Object.fromEntries(projectDataArray);

        const businessDataSumary = action.payload.items.sort((a, b) => (a.typeId || 'General').localeCompare(b.typeId || 'General'))
          .reduce((prev: IDictionary<DashboardDataItem>, next) => {
            if ((next.typeId || 'General') in prev) {
              prev[next.typeId || 'General'].amount += next.amount;
            } else {
              prev[next.typeId || 'General'] = { ...next }
            }
            return prev;
          }, {});

        state.businessData = Object.values(businessDataSumary);
      }).addCase(fetchDashboardDataAsync.pending, (state, action) => {
        state.loading = true;
      }).addCase(fetchDashboardDataAsync.rejected, (state, action) => {
        state.loading = false;
      })
    }
  });

export const { changeDashboardFilter } = dashboardSlice.actions;

export const dashboardDataSelecter = (state: RootState) => state.dashboard.data;
export const dashboardLoadingSelecter = (state: RootState) => state.dashboard.loading;
export const dashboardFilterSelecter = (state: RootState) => state.dashboard.filter;
export const dashboardProjectDataSelecter = (state: RootState) => state.dashboard.projectData;
export const dashboardProjectsSelecter = (state: RootState) => state.dashboard.data.projects;
export const dashboardBusinessDataSelecter = (state: RootState) => state.dashboard.businessData;

export default dashboardSlice.reducer