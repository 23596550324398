import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { IDictionary } from "../../app/types";
import { getLookupsByHeader } from "./lookup-api";
import { Lookup } from "./types";

export interface LookupState {
    lookups: IDictionary<Lookup[]>;
}

const initialState: LookupState = {
    lookups: {}
}

export const getLookupsByHeaderAsync = createAsyncThunk(
    'lookup/getbyheader',
    async (headerCode: string) => {
        const lookups = await getLookupsByHeader(headerCode);
        return {[headerCode]: lookups};
    });

const lookupSlice = createSlice({
    name: 'lookup',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getLookupsByHeaderAsync.fulfilled, (state, action) => {
            state.lookups = {...state.lookups, ...action.payload}
        })
    }
});

export const { } = lookupSlice.actions;

export default lookupSlice.reducer;

export const lookupsSelector = (state: RootState) => {
    return (headerCode: string) => state.lookup.lookups[headerCode] || []
}