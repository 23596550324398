import { coreApi } from "../../app/core-api";
import { EntityCreateResult, EntityUpdateResult, ListItem, PagedResult, PagedSearchFilter } from "../../app/types";
import {
    BulkTreeCreate,
    CropAnalyticsFilter,
    CropStat,
    CropStatBulkUpdateDto,
    CropStatFilter,
    CropStatLogCreateDto,
    CropStatsByZone,
    CropingSeason,
    CropingSeasonListItem,
    FallenStatsByZone,
    Tree,
    TreeFilter,
    TreeListItem,
} from "./crop-tracking.types";

export const cropTrackingApi = {
    addTreesBulk,
    searchTrees,
    updateTree,
    getTree,
    deleteTreesBulk,
    deleteTree,
    createCropingSeason,
    searchCropingSeasons,
    updateCropingSeason,
    getCropingSeason,
    deleteCropingSeason,
    getCropingSeasonsListItems,
    searchCropStats,
    bulkCreateCropStats,
    bulkUpdateCropStats,
    getCropStatByData,
    createCropStatLog,
    getCropStat,
    deleteCropStatLog,
    getCropStatsByZone,
    getFallenStatsByZone,
};

const baseUrlTrees = "trees";
const baseUrlCropingSeasons = "cropingseasons";
const baseUrlCropStats = "cropstats";

function addTreesBulk(bulkTrees: BulkTreeCreate) {
    return coreApi.post<number>(`${baseUrlTrees}/bulk`, bulkTrees);
}

function searchTrees(filter: PagedSearchFilter<TreeFilter>) {
    return coreApi.get<PagedResult<TreeListItem>>(baseUrlTrees, { ...filter.filter, ...filter.pagination });
}

function updateTree(id: string, tree: Tree) {
    return coreApi.put<EntityUpdateResult>(`${baseUrlTrees}/${id}`, tree);
}

function getTree(id: string) {
    return coreApi.get<Tree>(`${baseUrlTrees}/${id}`);
}

function deleteTreesBulk(bulkTrees: BulkTreeCreate) {
    return coreApi.remove<number>(`${baseUrlTrees}/bulk`, bulkTrees);
}

function deleteTree(id: string) {
    return coreApi.remove<number>(`${baseUrlTrees}/${id}`);
}

function createCropingSeason(data: CropingSeason) {
    return coreApi.post<EntityCreateResult>(baseUrlCropingSeasons, data);
}

function searchCropingSeasons(filter: any) {
    return coreApi.get<PagedResult<CropingSeasonListItem>>(baseUrlCropingSeasons, filter);
}

function updateCropingSeason(id: string, data: CropingSeason) {
    return coreApi.put<EntityUpdateResult>(`${baseUrlCropingSeasons}/${id}`, data);
}

function getCropingSeason(id: string) {
    return coreApi.get<CropingSeason>(`${baseUrlCropingSeasons}/${id}`);
}

function deleteCropingSeason(id: string) {
    return coreApi.remove(`${baseUrlCropingSeasons}/${id}`);
}

function getCropingSeasonsListItems(projectId: string) {
    return coreApi.get<ListItem[]>(`${baseUrlCropingSeasons}/list-items/${projectId}`);
}

function searchCropStats(filter: PagedSearchFilter<CropStatFilter>) {
    return coreApi.get<PagedResult<CropStat>>(baseUrlCropStats, { ...filter.filter, ...filter.pagination });
}

function bulkCreateCropStats(seasonId: string) {
    return coreApi.post<number>(`${baseUrlCropStats}/bulk-create/${seasonId}`, {});
}

function bulkUpdateCropStats(logDate: string, data: CropStatBulkUpdateDto[]) {
    return coreApi.post<number>(`${baseUrlCropStats}/bulk-update?logDate=${logDate}`, data);
}

function getCropStatByData(projectId: string, seasonId: string, zone: string, treeNumber: number) {
    return coreApi.get<CropStat>(`${baseUrlCropStats}/by-data`, { projectId, seasonId, zone, treeNumber });
}

function getCropStat(id: string) {
    return coreApi.get<CropStat>(`${baseUrlCropStats}/${id}`);
}

function createCropStatLog(log: CropStatLogCreateDto) {
    return coreApi.post<EntityCreateResult>(`${baseUrlCropStats}/log`, log);
}

function deleteCropStatLog(id: string) {
    return coreApi.remove<number>(`${baseUrlCropStats}/log/${id}`);
}

function getCropStatsByZone(filter: CropAnalyticsFilter) {
    return coreApi.get<CropStatsByZone[]>(`${baseUrlCropStats}/stats-by-zone`, { seasonId: filter.seasonId });
}

function getFallenStatsByZone(filter: CropAnalyticsFilter) {
    return coreApi.get<FallenStatsByZone[]>(`${baseUrlCropStats}/fallen-stats-by-zone`, { seasonId: filter.seasonId });
}
