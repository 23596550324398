import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Login } from "./features/auth/components/Login";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import GlobalError from "./components/GlobalError";
import SapToast from "./components/SapToast";
import "react-bootstrap-typeahead/css/Typeahead.css";
import TenantSwitcher from "./features/auth/components/TenantSwitcher";
import { MainLayout } from "./layout";
import PrivacyPage from "./features/other/PrivacyPage";
import { useEffect } from "react";
import { NotificationType, showApiErrorNotification, showNotification } from "./app/notification-service";
import { UnAuthLayout } from "./layout/UnAuthLayout";
import { AccountVerify } from "./features/auth/components/AccountVerify";
import ForgetPassword from "./features/auth/components/ForgetPassword";
import RecoverPassword from "./features/auth/components/RecoverPassword";

library.add(fas);

export default function App() {
    useEffect(() => {
        //Handle unhandled errors globally
        window.onunhandledrejection = (err: any) => {
            if (err.reason?.response?.status) {
                showApiErrorNotification(err.reason);
            } else {
                showNotification(
                    NotificationType.error,
                    "Unknown Error. If error continue, Please contact SME Plus IT support."
                );
            }
        };
    }, []);

    return (
        <>
            <Routes>
                <Route path="/account" element={<UnAuthLayout />}>
                    <Route path="login" element={<Login />} />
                    <Route path="confirm" element={<AccountVerify />} />
                    <Route path="forget-password" element={<ForgetPassword />} />
                    <Route path="reset-password" element={<RecoverPassword />} />
                </Route>
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="*" element={<MainLayout />} />
            </Routes>
            <GlobalError />
            <SapToast />
            <TenantSwitcher />
        </>
    );
}
